import logger from "@scripts/logger";
import * as Sentry from '@sentry/browser';

Sentry.init({
  dsn:  import.meta.env.VITE_SENTRY_DNS,
  integrations: [Sentry.browserTracingIntegration()],

  // Set tracesSampleRate to 1.0 to capture 100%
  // of transactions for performance monitoring.
  // We recommend adjusting this value in production
  tracesSampleRate: import.meta.env.VITE_DEBUG?1.0:0.1,
  // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
  tracePropagationTargets: ["localhost", import.meta.env.VITE_INSTANCE_HOSTNAME],
});


/** 
 * Polyfill below, core-js lib and @vite/legacy don't work
 * 
 * Used mainly for asyncQuerySelector
 * 
 * to test resolver, add : delete Promise.withResolvers;
*/
if (!Promise.withResolvers) {
  Promise.withResolvers = <T>() => {
    let resolve: (value: T | PromiseLike<T>) => void;
    let reject: (reason?: any) => void;
    const promise = new Promise<T>((res, rej) => {
      resolve = res;
      reject = rej;
    });
    return { promise, resolve, reject };
  };
}

/* Replace below with env call */
const DEBUG= import.meta.env.VITE_DEBUG;
logger.info(`debug is ${DEBUG}`)
if (DEBUG==="true"){
  console.log("debug on")
}
else{
  console.log = function(){};
  logger.debug = function(){};
}

import "moment/locale/fr";
import {
  getMobileOperatingSystem,
  openLinkInApps,
  openLinksInNav,
} from "@scripts/open-outbound-links";
import {
  TwitterEOMultipleContentOptions,
  addToPlateformCounter,
  checkChosenRepresentatives,
  consultationChooseOption,
  copyElement,
  displayElement,
  displayModal,
  formatMapResult,
  getCookie,
  getEmail,
  hideElement,
  launchLoadingScripts,
  listenSelectRepresentativeCardClick,
  manageSelectLanguage,
  representativeSearchUpdateFunction,
  scriptLauncherActions,
  scrollToDestination,
  selectAllRepresentatives,
  selectRepresentativeCardClick,
  showHideBlocks,
  solidMapSearch,
  visibilityControler,
  visibilityControlerTargettedMethod,
} from "@scripts/risefor";

import {
  addFederatedInstaceDataSource,
  clickDisplaySelectedOfficialsCTA,
  clickElement,
  customizeRepresentativeContent,
  displayContactModule,
  emailSentCounter,
  initContactRepresentatives,
  loadActionUrl,
  openMailBox,
} from "@scripts/contact-representatives";

import { NotificationLoadLawID } from "@scripts/law_tracker";
import "@scripts/components/tab-display";
import { launchInitScripts } from "@scripts/launchers";



document.addEventListener("DOMContentLoaded", () => {
  launchInitScripts("auto");
  launchLoadingScripts("auto");
  console.log("Dom loaded");
  /* ====
	MOBILE MENU
	====*/
  const menucall = document.getElementById("call-mobile-menu");
  const mainmenu = document.getElementById("main-menu");

  menucall?.addEventListener("click", () => {
    menucall.classList.toggle("menu-on-l");
    mainmenu?.classList.toggle("menu-on-l");
  });

  mainmenu?.addEventListener("click", () => {
    menucall?.classList.toggle("menu-on-l");
    mainmenu.classList.toggle("menu-on-l");
  });

  initContactRepresentatives();
  setTimeout(() => {
    // logger.debug("first router nav scroll top",e);
    window.scrollTo({ top: 0 });
  }, 1000);
}); // END of EventListener "DOMContentLoaded"

// inject functions into the window
// eslint-disable-next-line @typescript-eslint/no-explicit-any
const w: any = window;
w.launchLoadingScripts = launchLoadingScripts;
w.consultationChooseOption = consultationChooseOption;
w.scrollToDestination = scrollToDestination;
w.scriptLauncherActions = scriptLauncherActions;
w.showHideBlocks = showHideBlocks;
w.displayElement = displayElement;
w.hideElement = hideElement;
w.copyElement = copyElement;
w.TwitterEOMultipleContentOptions = TwitterEOMultipleContentOptions;
w.representativeSearchUpdateFunction = representativeSearchUpdateFunction;
w.selectRepresentativeCardClick = selectRepresentativeCardClick;
w.listenSelectRepresentativeCardClick = listenSelectRepresentativeCardClick;
w.selectAllRepresentatives = selectAllRepresentatives;
w.getEmail = getEmail;
w.displayModal = displayModal;
w.visibilityControler = visibilityControler;
w.visibilityControlerTargettedMethod = visibilityControlerTargettedMethod;
w.manageSelectLanguage = manageSelectLanguage;
w.solidMapSearch = solidMapSearch;
w.formatMapResult = formatMapResult;

w.getMobileOperatingSystem = getMobileOperatingSystem;
w.openLinkInApps = openLinkInApps;
w.openLinksInNav = openLinksInNav;

w.addToPlateformCounter = addToPlateformCounter;
w.checkChosenRepresentatives = checkChosenRepresentatives;
w.getCookie = getCookie;

w.clickDisplaySelectedOfficialsCTA = clickDisplaySelectedOfficialsCTA;
w.displayContactModule = displayContactModule;
w.loadActionUrl = loadActionUrl;
w.customizeRepresentativeContent = customizeRepresentativeContent;
w.openMailBox = openMailBox;
w.addFederatedInstaceDataSource = addFederatedInstaceDataSource;
w.emailSentCounter = emailSentCounter;
w.clickElement = clickElement;
w.NotificationLoadLawID = NotificationLoadLawID;
