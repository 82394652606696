export const socialMediaShareLinksValues = {
  whatsapp: {
    desktop: "https://web.whatsapp.com/send?text=",
    mobile: "https://api.whatsapp.com/send?text=",
  },
  twitter: {
    desktop: "https://twitter.com/intent/tweet?text=",
    mobile: "twitter://post?text=",
  },
  facebook: {
    desktop: "https://www.facebook.com/sharer/sharer.php?&u=",
    mobile: "fb://sharer/?&u=",
  },
  messenger: {
    desktop: "https://www.facebook.com/dialog/send?",
    mobile: "messenger://t/",
  },
  telegram: {
    desktop: "https://t.me/share/url?",
    mobile: "https://t.me/share/url?",
  },
  signal: {
    desktop: "sgnl://?",
    mobile: "sgnl://?",
  },
};
