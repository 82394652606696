import logger from "./logger";
import { copyElement } from "./risefor";
import { socialMediaShareLinksValues } from "./social-media-platforms";
/**
 * Scripts for mobile specific interactions, appart from menu
 */

/** **********
 * Detect the device
 ************/
export function getMobileOperatingSystem() {
  const userAgent =
    navigator.userAgent ||
    navigator.vendor ||
    ("opera" in window && (window as { opera: string }).opera) ||
    "";

  // Windows Phone must come first because its UA also contains "Android"
  if (/windows phone/i.test(userAgent)) {
    return "Windows Phone";
  }

  if (/android/i.test(userAgent)) {
    return "Android";
  }

  // iOS detection from: http://stackoverflow.com/a/9039885/177710
  if (/iPad|iPhone|iPod/.test(userAgent) && !("MSSTream" in window)) {
    return "iOS";
  }
  return "unknown";
}

/** **********
 * Give choice to open link in apps
 ************/
// navigator.getInstalledRelatedApps().then(relatedApps => {
//     logger.debug("related apps are",relatedApps);
//     for (let app of relatedApps) {
//       window.alert(app.platform);
//       window.alert(app.url);
//       window.alert(app.id);
//     }
// });
type SharePlatform = keyof typeof socialMediaShareLinksValues;
export function openLinkInApps(
  sharePlateform: SharePlatform,
  shareText: string,
  currentPageUrl: string,
) {
  logger.debug("open link in app", openLinkInApps);
  const whatsappUrl = socialMediaShareLinksValues["whatsapp"]["mobile"];
  const twitterUrl = socialMediaShareLinksValues["twitter"]["mobile"];
  const facebookUrl = socialMediaShareLinksValues["facebook"]["mobile"];
  const messengerUrl = socialMediaShareLinksValues["messenger"]["mobile"];
  const telegramUrl = socialMediaShareLinksValues["telegram"]["mobile"];
  const signalUrl = socialMediaShareLinksValues["signal"]["mobile"];
  logger.debug(`app click for ${sharePlateform}`);
  switch (sharePlateform) {
    case "whatsapp":
      // window.alert('whatsapp url',whatsappUrl)
      window.open(`${whatsappUrl + shareText}%20${currentPageUrl}`, "_blank");
      return;
    case "facebook":
      window.open(
        `${facebookUrl + encodeURI(currentPageUrl)}&quote=${shareText}`,
        "_blank",
      );
      return;

    case "twitter":
      window.open(`${twitterUrl + shareText}%20${currentPageUrl}`, "_blank");
      return;

    // messenger
    case "messenger":
      window.open(
        `${messengerUrl}&link=${currentPageUrl}&text=${shareText}`,
        "_blank",
      );
      return;

    // signal
    case "signal":
      window.open(
        `${signalUrl}url=${encodeURI(currentPageUrl)}&text=${shareText}`,
        "_blank",
      );
      return;
    // telegram
    case "telegram":
      window.open(
        `${telegramUrl}url=${encodeURI(currentPageUrl)}&text=${shareText}`,
        "_blank",
      );
      return;
  }
}

export function openLinksInNav(
  sharePlateform: SharePlatform | "copy-link",
  shareText: string,
  currentPageUrl: string,
) {
  const whatsappUrl = socialMediaShareLinksValues["whatsapp"]["desktop"];
  const twitterUrl = socialMediaShareLinksValues["twitter"]["desktop"];
  const facebookUrl = socialMediaShareLinksValues["facebook"]["desktop"];
  const messengerUrl = socialMediaShareLinksValues["messenger"]["desktop"];
  const telegramUrl = socialMediaShareLinksValues["telegram"]["desktop"];
  const signalUrl = socialMediaShareLinksValues["signal"]["desktop"];
  let sharedMessage = "";
  switch (sharePlateform) {
    case "copy-link":
      // window.alert("copying"+currentPageUrl)
      // copyCurrentPageUrl = $('<input>').val(currentPageUrl).appendTo('body').select()
      copyElement("default", currentPageUrl);
      // logger.debug("content is",copyCurrentPageUrl);
      // navigator.clipboard.writeText(copyCurrentPageUrl[0].value)
      // window.alert("Le lien "+currentPageUrl+" a été copié")
      break;
    case "whatsapp":
      sharedMessage = `${whatsappUrl + shareText}%20${currentPageUrl}`;
      window.open(sharedMessage, "_blank");
      break;
    case "facebook":
      sharedMessage = `${facebookUrl + encodeURI(currentPageUrl)}&quote=${shareText}`;
      window.open(sharedMessage, "_blank");
      break;

    case "twitter":
      sharedMessage = `${twitterUrl + shareText}%20${currentPageUrl}`;
      window.open(sharedMessage, "_blank");
      break;

    // messenger
    case "messenger":
      sharedMessage = `${messengerUrl}&link=${currentPageUrl}&text=${shareText}`;
      window.open(`https://m.me/?link${currentPageUrl}`);
      break;

    case "signal":
      sharedMessage = signalUrl; // + "url=" + encodeURI(currentPageUrl) + "&text="+ shareText;
      logger.debug("url", sharedMessage);
      window.open(sharedMessage, "_blank");
      break;

    // telegram
    case "telegram":
      sharedMessage = `${telegramUrl}url=${encodeURI(currentPageUrl)}&text=${shareText}`;
      // logger.debug("url",sharedMessage)
      window.open(sharedMessage, "_blank");
      break;

    default:
      break;
  }
}
