export function filterNullKeys<U>(
	dict: Record<string, U>,
): Record<string, NonNullable<U>> {
	return Object.keys(dict).reduce(
		(o: Record<string, NonNullable<U>>, k) => {
			const v = dict[k];
			if (v != null) o[k] = v;
			return o;
		},
		{} as Record<string, NonNullable<U>>,
	);
}
