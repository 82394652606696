//@ts-nocheck
import config from "../config.risefor.json";
import logger from "@scripts/logger";

export function launchInitScripts(launchType: "auto" | undefined) {
  //Set sources
  console.log("site config ", config);
  console.log("site config ", config.clientContext.name);
  const federated_sources = config.federated_sources;
  logger.debug("federated sources are", federated_sources);
  const all_sources = [];
  // set local source
  all_sources.push({
    "@id": `${config.clientContext.server}/active-actions/`,
    "@type": "ldp:Container",
  });
  for (const [name, hostname] of Object.entries(federated_sources)) {
    console.log("source is", hostname);
    all_sources.push({
      "@id": `${hostname}/active-actions/`,
      "@type": "ldp:Container",
    });
  }
  logger.debug("all wanted sources are", all_sources);

  // Seems like sib has a bug - If same @id is set on 2 pages, it will bug on the 2nd one
  // Workaround - set 2 @id's
  setLocalDataSrc(all_sources);
}

async function setLocalDataSrc(all_sources) {
  if (!window.sibStore) {
    console.error('sibStore is not available');
    setTimeout(() => {
      console.warn("waiting 1 second, hope sibStore will be available")
    }, 1000);
  }
  window.sibStore
    .setLocalData(
      {
        "@cache": "false",
        "@context": "https://cdn.startinblox.com/owl/context.jsonld",
        "@type": "sib:federatedContainer",
        "@id": "store://local.activeactions/active-actions-homepage/",
        "ldp:contains": all_sources,
        permissions: [{ mode: { "@type": "view" } }],
      },
      "store://local.activeactions/active-actions-homepage/",
    )
    .then((_) => {
      window.sibStore.setLocalData(
        {
          "@cache": "false",
          "@context": "https://cdn.startinblox.com/owl/context.jsonld",
          "@type": "sib:federatedContainer",
          "@id": "store://local.activeactions/active-actions-listing/",
          "ldp:contains": all_sources,
          permissions: [{ mode: { "@type": "view" } }],
        },
        "store://local.activeactions/active-actions-listing/",
      );
    });
    // .then((_) => {
    //   setTimeout(() => {
    //     window.sibStore.setLocalData(
    //       {
    //         "@cache": "false",
    //         "@context": "https://cdn.startinblox.com/owl/context.jsonld",
    //         "@type": "sib:federatedContainer",
    //         "@id": "store://local.activeactions/see-all-slider/",
    //         "ldp:contains": all_sources,
    //         permissions: [{ mode: { "@type": "view" } }],
    //       },
    //       "store://local.activeactions/see-all-slider/",
    //     );
    //   }, 1000);
    // });
}
