/*
JS that handle modules linked to contacting elected officials
*/
// import { store } from 'https://unpkg.com/@startinblox/core@0.10';
import {
	asyncQuerySelector,
	asyncQuerySelectorAll,
} from 'async-query-selector-all/dist/async-query-selector';

import logger from "@scripts/logger";
import { displayModal, scrollToDestination } from "@scripts/risefor";
import type { SIBElement } from "@scripts/types";

// listen form DOM load
export async function initContactRepresentatives() {
  const page = document.querySelector<HTMLElement>("#action-group-detail");
  logger.debug("Const action page is", page);
  /** *******
     Launch Action Page scripts & listeners
    ********/

  if (!page)
    throw new Error(
      "page is not loaded or element is not present #action-group-detail",
    );

  /**
   * "Navigation Listener
   * "Use first display populate to launchers
   */
  // const firstDisplay = page.querySelector("solid-display")!;
  const asyncFirstDisplay = await asyncQuerySelector("solid-display",page)
  console.log(`async first page display ${asyncFirstDisplay}`)
  
  asyncFirstDisplay.addEventListener(
    "populate",
    () => {
      logger.debug("First display populated", asyncFirstDisplay);
      // display representatvie contact CTA's that are chosen
      hideContactRepresentativeSections(page);
      // hide contact modules
      hideContactRepresentativeModules(page);
      const actionSearches = page?.querySelectorAll<
        SIBElement & HTMLFormElement
      >("solid-form-search");
      logger.debug("search modules", actionSearches);
      if (actionSearches != null) {
        for (const actionSearch of Array.from(actionSearches)) {
          const actionSearchName = actionSearch.attributes.getNamedItem("name");
          if (actionSearchName) {
            if (
              actionSearchName.value === "action-representative-postal-search"
            ) {
              setTimeout(() => {
                logger.debug("action page search listener");
                actionPageSearchListener(page, actionSearch);
              }, 1000);
              // representativeContactAction(page, searchModule);
            } else {
              // logger.debug("search module name", actionSearchName.value);
            }
          }
        }
      }
    },
    { once: true },
  );
  /**
   * Listen to forms of action page
   */
  const actionForms = page.querySelectorAll<HTMLFormElement>("solid-form");
  for (const form of Array.from(actionForms)) {
    if (form) {
      form.addEventListener("populate", () => {
        // logger.debug("Action form populating", form);
        const formName = form.attributes.getNamedItem("name");
        if (!formName) {
          console.log("Action form has no name" + form);
        }
        // Representative Email form
        else if (formName.value.includes("representative-email")) {
          /*
           * Form click listener
           */

          // add event listener for "send" click in order to improve dialog
          const formSubmit = form.querySelector("input[type=submit]");
          formSubmit?.addEventListener("click", () => {
            // load action url in form now
            // loadActionUrl(page, form);
            // updateEmailWithSelectedRepresentatives(form);
            displayEmailInDialog(form);
            /* EVOLUTION -  DISPLAY LOADER BETWEEN CLICK AND SUBMIT
			-  https://git.risefor.org/applications/risefor-front/-/work_items/111
			- var below can be useful, old way used to use it
			dialogSubmitButton = form.querySelector('dialog  button:first-child') 
			*/
          });
        }
      });
    }
  }
  /**
   * Listen to displays of action page
   */
  const actionDisplays = page.querySelectorAll<SIBElement>("solid-display");
  // logger.debug("all displays are",actionDisplays);
  for (const actionDisplay of Array.from(actionDisplays)) {
    actionDisplay.addEventListener("populate", (displayPopulateEvent) => {
      // displayPopulateEvent.preventDefault;
      // displayPopulateEvent.cancelBubble;
      // displayPopulateEvent.stopImmediatePropagation();
      const displayName = actionDisplay?.attributes?.getNamedItem("name");
      if (displayName) {
        // email content display
        if (displayName.value === "action-status") {
          setTimeout(() => {
            logger.debug("launching deactivator", displayPopulateEvent);
            endedActionDeactivator(page);
          }, 500);
        }
        if (displayName.value === "email-counter-display") {
          setTimeout(() => {
            emailSentCounter(actionDisplay);
          }, 500);
        }
        if (displayName.value === "action-hostname") {
          setTimeout(() => {
            const currentHostName = window.origin;
            const actionOriginHostName = document.querySelector(
              "*[name='instanceHostName']",
            )?.textContent;
            // if the action has been launched by current site, hide instance info below
            // logger.debug("Federation block visibility", currentHostName, actionOriginHostName);
            const federatedActionInfoBlocks =
              document?.querySelectorAll<SIBElement>(
                'solid-display[name=federated-action-information],*[class*="federated-action-information"]',
              );
            logger.debug("federated selector", federatedActionInfoBlocks);
            // if (federatedActionInfoBlock) {
            // 	if (currentHostName == actionOriginHostName) {
            // 		federatedActionInfoBlock.style.display = 'none';
            // 	} else {
            // 		federatedActionInfoBlock.style.display = 'block';
            // 	}
            // }
            for (const federatedActionInfoBlock of Array.from(
              federatedActionInfoBlocks,
            )) {
              logger.debug("federated block is", federatedActionInfoBlock);
              if (federatedActionInfoBlock) {
                if (currentHostName === actionOriginHostName) {
                  federatedActionInfoBlock.style.setProperty(
                    "display",
                    "none",
                    "important",
                  );
                } else {
                  federatedActionInfoBlock.style.display = "block";
                }
              }
            }
          }, 500);
          setTimeout(() => {
            // add domain to search field
            addFederatedInstaceDataSource(
              "action-page-representative-search",
              "/search-representative/", //update to ssr search
            );
            // add domain to contact form
            addFederatedInstaceDataSource(
              "manual-representative-email-send-form",
              "/electedofficialcontacts/",
            );
            addFederatedInstaceDataSource(
              "representative-email-send-form",
              "/electedofficialcontacts/",
            );
          }, 2000); // increase delay to reduce impact of distant sources on load time
        }
      }
    });
    // if (actionDisplay?.attributes?.name?.value == 'action-page-representative-search') {
    //   setTimeout(() => {
    //   }, 2000);
    // }
  }
}

/**
 * Add original instance name to data sources to make sure posts are sent there
 * @param {*} containerId - container to update
 * @param {*} wantedUri - URI of the resource wanted
 */
export function addFederatedInstaceDataSource(
  containerName: string,
  wantedUri: string,
) {
  const originalActionDomain = document.querySelector(
    "*[name='instanceHostName']",
  )?.textContent;
  const containerToUpdate = document.querySelectorAll<HTMLElement>(
    `*[id=${containerName}]`,
  );
  for (const container of Array.from(containerToUpdate)) {
    container.dataset.src = originalActionDomain + wantedUri;
  }
}

function actionPageSearchListener(
  page: HTMLElement,
  searchForm: HTMLFormElement,
) {
  const submitButton =
    searchForm?.querySelector<HTMLInputElement>("input[type=submit]");
  submitButton?.addEventListener("click", () => {
    // update button text
    submitButton.value = "Mettre à jour";

    // hide modules CtA's & lines
    const modulesToHideIds = [
      "representative-contact-modules",
      // 'representative-contact-content-email',
      // 'representative-contact-content-twitter',
      // 'representative-contact-content-call',
      // 'representative-contact-content-instagram',
      // 'manual-contact-content-email',
      // 'manual-contact-content-twitter',
      // 'manual-contact-content-call',
      // 'manual-contact-content-instagram',
    ];
    for (const modulesToHideId of modulesToHideIds) {
      const moduleLine = page?.querySelector<HTMLElement>(
        `#${modulesToHideId}`,
      );
      logger.debug("hiding module", moduleLine);
      if (moduleLine) moduleLine.style.display = "none";
    }

    // display representatives CTA's
    const targetedOfficialsChoiceBar = page?.querySelector<HTMLElement>(
      "#display-choose-officials",
    );
    if (targetedOfficialsChoiceBar) {
      // logger.debug("officials are",targetedOfficialsChoiceBar);
      targetedOfficialsChoiceBar.style.display = "block";
    }

    // Uncheck input buttons, in case they were previously checked
    const radioButtons =
      targetedOfficialsChoiceBar?.querySelectorAll<HTMLInputElement>(
        "input[type=radio], input[type=checkbox]",
      );
    if (radioButtons) uncheckRadioButtons(radioButtons);

    // If there's only one choice available, then click it
    if (radioButtons?.length === 1) {
      const radioButton = radioButtons[0];
      logger.debug("only one targetting type");
      setTimeout(() => {
        clickRadioButton(radioButton);
      }, 500);
    }

    targetedOfficialsChoiceBar &&
      window.setTimeout(() => {
        targetedOfficialsChoiceBar.style.opacity = "1";
        targetedOfficialsChoiceBar.style.transform = "scale(1)";
      }, 0);
    scrollToDestination("targetID", "display-choose-officials", "start");
  });
}

function endedActionDeactivator(page: HTMLElement) {
  const actionStatus = page.querySelector<SIBElement>(
    "solid-display[name=action-status]",
  )?.innerText;
  logger.debug("action status", actionStatus);
  const buttonList = page.querySelectorAll<HTMLInputElement>(
    "solid-form[name=representative-email-form] dialog button",
  );
  const submitButtons: HTMLInputElement[] = [];
  for (const button of Array.from(buttonList)) {
    if (button?.innerText.toLowerCase().includes("envoyer")) {
      submitButtons.push(button);
    }
  }
  if (actionStatus === "finished") {
    // deactivate all buttons
    deactivateButtonEndedAction(submitButtons, "deactivate");
    window.alert(
      "Information : \rCette action est maintenant terminée, vous ne pourrez pas envoyer d'email",
    );
  } else {
    // Reacticate buttons, in case when navigating they stay deactivated
    deactivateButtonEndedAction(submitButtons, "activate");
  }
}

/**
 * When action action is over, disable buttons
 * * @param {*} List of button to interact with
 * * @param {string} What to do whit the buttons
 */
function deactivateButtonEndedAction(
  buttonList: HTMLInputElement[],
  action: "activate" | "deactivate",
) {
  for (const button of buttonList) {
    if (action === "deactivate") {
      logger.debug("uncheck button", button);
      button.disabled = true;
    } else {
      logger.debug("check buttons", button);
      button.disabled = false;
    }
  }
}

/**
 * Function to uncheck buttons
 * * @param {*} NodeListOf List of buttons to deactivate
 */
function uncheckRadioButtons(buttonList: NodeListOf<HTMLInputElement>) {
  for (const button of Array.from(buttonList)) {
    button.checked = false;
    // logger.debug("uncheck radio", button);
  }
}
/**
 * Function to click on button & launch related action
 * * @param {*} button An input tag
 */
function clickRadioButton(button: HTMLInputElement) {
  logger.debug("click here", button);
  button.click();
}

/**
 * Select an element and click it
 * * @param {HTMLElement} Location of the click
 * * @param {string} Target Query Selector
 */
export function clickElement(
  clickLocation: HTMLElement,
  targetSelector: string,
) {
  const toClick = document.querySelector<HTMLElement>(targetSelector);
  logger.debug("click target", toClick);
  if (toClick) {
    toClick.click();
  }
}

function addChosenRepresentativesToEmail(form: HTMLFormElement) {
  // get user name & last name
  logger.debug("add chosen representatives to form", form);
  // get chosen representatives names
  const representativeSearchSection =
    form.closest("section")?.previousElementSibling;
  let listedRepresentatives =
    representativeSearchSection?.querySelectorAll<HTMLInputElement>(
      'solid-display[class="representative-search"] > div > solid-display', //Postal code search
    );
  // Temp fix - Query select above doesnt match when using targeted search module
  // Need to review the template and regroupe the forms, we don't need 2 forms anymore
  if (listedRepresentatives && listedRepresentatives.length === 0) {
    listedRepresentatives =
      representativeSearchSection?.querySelectorAll<HTMLInputElement>(
        "u4e-targeted-representative-widget > solid-display > div > solid-display", //Specific targets
      );
  }
  // logger.debug("Selected representatives are",listedRepresentatives)

  const representativeNames: string[] = [];
  //if listedRepresentatives, stop
  if (!listedRepresentatives) {
    return;
  }
  for (const representative of Array.from(listedRepresentatives)) {
    const chosenStatus = representative?.querySelector<HTMLInputElement>(
      "u4e-display-checkbox input",
    )?.checked;
    if (chosenStatus === true) {
      let fullName =
        representative.querySelector<HTMLElement>(
          "div[name=fullname]",
        )?.innerText;
        fullName = fullName?.replace(/\d+\./,"").trim() // remove numbers in case of ordering
      const civility =
        representative.querySelector<HTMLElement>(
          "div[name=civility]",
        )?.innerText;

      fullName = `${civility?.trim()} ${fullName?.trim()}`.trim();
      logger.debug("Full name is", fullName);
      representativeNames.push(fullName);
    }
  }
  const names = representativeNames.join(", ");

  const emailContent = form.querySelector<HTMLTextAreaElement>(
    "textarea[name=content]",
  )?.value;
  
  const representativePlaceholder = emailContent?.replace(
    /\[Madame\/Monsieur\]/g,
    names,
  );
  const ta = form.querySelector<HTMLTextAreaElement>("textarea[name=content]");
  logger.debug(`form is ${ta?.value} ${representativePlaceholder}`)
  if (ta && representativePlaceholder) ta.value = representativePlaceholder;
}

/** ***
    * On email send, add content of email to validation dialog
    @params solid-display
*****/
function displayEmailInDialog(form: HTMLFormElement) {
  const formDialog = form.querySelector<HTMLElement>("dialog");
  if (!formDialog) {
    return;
  }
  // check if there's already content added to dialog, if so, remove it
  const contentInDialog = formDialog?.querySelector(
    "#representative-email-review",
  );
  if (contentInDialog != null) {
    const formDialogText = formDialog?.querySelector<HTMLElement>(":scope > p");
    formDialogText?.removeChild(contentInDialog);
  }
  const dialogDiv = document.createElement("div");

  // let newTitleTag = document.createElement("h3");
  // let newTitleText= document.createTextNode("Vous allez envoyez l'email suivant:");
  // newTitleTag.appendChild(newTitleText);

  const emailSubjectTag = document.createElement("p");
  const currentSubject = form.querySelector<HTMLInputElement>(
    "input[name=subject]",
  )?.value;
  const emailSubjectText = document.createTextNode(currentSubject ?? "");
  emailSubjectTag.appendChild(emailSubjectText);

  const emailContentTag = document.createElement("p");
  const currentContent = form.querySelector<HTMLTextAreaElement>(
    "textarea[name=content]",
  )?.value;
  // logger.debug("content",currentContent);
  // emailContentTag.appendChild(emailContentText);

  const existingDialogTitle = form.querySelector<HTMLElement>("dialog > p");
  existingDialogTitle?.appendChild(dialogDiv);
  dialogDiv.id = "representative-email-review";
  // dialogDiv.appendChild(newTitleTag);
  // newTitleTag.classList.add('mt-2')
  dialogDiv.appendChild(emailSubjectTag);
  emailSubjectTag.classList.add("bold", "pb-0");
  dialogDiv.appendChild(emailContentTag);
  emailContentTag.innerText += currentContent?.toString();

  // listen for click & validation text
  const formButtons = form.querySelectorAll("dialog  button");
  // logger.debug("form dialog buttons",formButtons);
  for (const button of Array.from(formButtons)) {
    button.addEventListener("click", () => {
      const formDialogText = formDialog?.querySelector(":scope > p");
      // logger.debug("click", button);

      //TODO : review this, there's only 1 email-review, no need for the 'all' & iteration
      const addedContents = formDialog?.querySelectorAll(
        "#representative-email-review",
      );
      if (!addedContents) {
        return;
      }
      for (const addedContent of Array.from(addedContents)) {
        formDialogText?.removeChild(addedContent);
      }
    });
  }
  //   logger.debug("goint to sroll to top of dialog", formDialog);
  setTimeout(() => {
    //timeout to be sure it's on screen and applied
    formDialog.scrollTo({ top: 0 });
  }, 200);
}

/** ***
    * Display counter of emails sent
    @params solid-display
*****/
export function emailSentCounter(actionDisplay: HTMLElement) {
  const numberOfParticipantsText = document.querySelector(
    "#numberOfParticipants",
  )?.innerHTML;
  const numberOfParticipants = Number.parseInt(numberOfParticipantsText ?? "0");
  let totalParticipants = 10;
  // Adapt total number when participants reached
  const totalNode =
    actionDisplay.querySelector<HTMLElement>("#totalParticipants");
  if (!totalNode) {
    return;
  }
  if (numberOfParticipants >= totalParticipants) {
    // if we're at 100%, update total to next goal
    if (numberOfParticipants / totalParticipants === 1) {
      totalParticipants = totalParticipants + 1;
    }
    // customize total
    // > if under 1000, but hundreds
    // > if over 1000 by thousands
    // > if over 10k by 10k
    // logger.debug("number of participants",numberOfParticipants);
    if (numberOfParticipants >= 10000) {
      totalParticipants = Math.ceil(numberOfParticipants / 10000.0) * 10000;
    } else if (numberOfParticipants >= 1000) {
      totalParticipants = Math.ceil(numberOfParticipants / 1000.0) * 1000;
    } else {
      totalParticipants = Math.ceil(numberOfParticipants / 100.0) * 100;
    }
    totalNode.innerText = `${totalParticipants}`;
  } else {
    totalNode.innerText = `${totalParticipants}`;
  }
  // calculate the percentage of sents vs totals
  const percentageOfTotal = (numberOfParticipants / totalParticipants) * 100;

  // plug it into the percentage bar
  // progress bar source code: https://codepen.io/vitoralberto/pen/raqZpE
  $({
    countNum: $(".bar p").html(),
  }).animate(
    {
      countNum: percentageOfTotal.toFixed(2),
    },
    {
      duration: 3000,
      easing: "linear",
      step: function () {
        $(".bar p").html(`${Math.floor(Number.parseFloat(this.countNum))}%`);
        $(".bar").css(
          "width",
          `${Math.floor(Number.parseFloat(this.countNum))}%`,
        );
      },
      complete: function () {
        $(".bar p").html(`${this.countNum}%`);
        $(".bar").css("width", `${this.countNum}%`);
        // alert('finished');
      },
    },
  );
}

/** *********************
 * REPRESENTATIVE CONTACT CTA'S
 * Display Module CTAS
 * Hide existing modules if displayed
 * @param {*} clicLocation Clic element
 * @param {*} representativeFunction section id
 **********************/
export function clickDisplaySelectedOfficialsCTA() {
  // logger.debug("function",representativeFunction);
  const representativeModulesCTAs = document.querySelector<HTMLElement>(
    "#representative-contact-modules",
  );
  if (!representativeModulesCTAs) {
    return;
  }
  const visibilityStatus = representativeModulesCTAs?.style.display ?? "block";
  // uncheck previously checked radio buttons
  const radioButtons =
    representativeModulesCTAs?.querySelectorAll<HTMLInputElement>(
      "input[type=radio]",
    );
  for (const radioButton of Array.from(radioButtons)) {
    radioButton.checked = false;
  }
  // if hidden, then just display
  if (visibilityStatus === "" || visibilityStatus === "none") {
    representativeModulesCTAs.style.display = "block";
    // logger.debug("clicked button");
  }
  // if the block is already displayed, then hide existing elements
  // && re-display just CTA's
  else if (visibilityStatus === "block") {
    // hide contact modules elements
    const contactModules = document.querySelectorAll<HTMLElement>(
      "#representative-contact-content-email,#representative-contact-content-twitter,#representative-contact-content-call,#representative-contact-content-instagram",
    );
    for (const contactModule of Array.from(contactModules)) {
      contactModule.style.display = "none";
    }
    // hide cta line
    representativeModulesCTAs.style.display = "none";
    setTimeout(() => {
      // reload cta line
      representativeModulesCTAs.style.display = "block";
    }, 500);
  }
  // if there is just one choice given, click on it
  if (radioButtons.length === 1) {
    setTimeout(() => {
      clickRadioButton(radioButtons[0]);
    }, 1000);
  }
}

/** *********************
 * HIDE REPRESENTATIVE CONTACT SECTION
 * In case section was opened on another action
 **********************/
function hideContactRepresentativeSections(page: HTMLElement) {
  const contactRepresentativeSections = page.querySelectorAll(
    ".elected-officials-contact > div",
  );
  // logger.debug("contact representative sections",contactRepresentativeSections);
  for (const contactRepresentativeSection of Array.from(
    contactRepresentativeSections,
  )) {
    contactRepresentativeSection.setAttribute("style", "display:none");
    // logger.debug("contact section", contactRepresentativeSection, contactRepresentativeSection.attributes);
  }
}

/** *********************
 * HIDE REPRESENTATIVE CONTACT Modules
 * In case section was opened on another action
 **********************/
export function hideContactRepresentativeModules(page: HTMLElement) {
  const contactRepresentativeModules = page.querySelectorAll(
    "*[id=display-choose-officials], #representative-contact-content-instagram, #manual-contact-content-instagram",
  );
  console.log("hidding contact rep content", contactRepresentativeModules);
  // logger.debug("contact representative Modules",contactRepresentativeModules);
  for (const contactRepresentativeModule of Array.from(
    contactRepresentativeModules,
  )) {
    contactRepresentativeModule.setAttribute("style", "display:none");
    // logger.debug("contact Module", contactRepresentativeModule, contactRepresentativeModule.attributes);
  }
}
/* ========================
 *  REPRENSENTATIVE CONTACT 
    - Onclick "Email/Twitter" display module  
===========================*/
/* Display the content
 */
export function displayContactModule(targetingType: string, contentId: string) {
  // logger.debug("display block",targetingType,contentId);
  let moduleSectionIds;
  if (targetingType.toLowerCase().includes("manual")) {
    moduleSectionIds = {
      email: "manual-contact-content-email",
      twitter: "manual-contact-content-twitter",
      phone: "manual-contact-content-call",
      instagram: "manual-contact-content-instagram",
    };
    // just for Manual selection, load email & twitter values here
    customizeRepresentativeContent("manual-targeting");
  } else {
    moduleSectionIds = {
      email: "representative-contact-content-email",
      twitter: "representative-contact-content-twitter",
      phone: "representative-contact-content-call",
      instagram: "representative-contact-content-instagram",
    };
  }
  logger.debug("contentId match", contentId);
  if (contentId.toLowerCase().includes("email")) {
    contentId = moduleSectionIds["email"];
  } else if (contentId.toLowerCase().includes("twitter")) {
    contentId = moduleSectionIds["twitter"];
  } else if (
    contentId.toLowerCase().includes("téléphone") ||
    contentId.toLowerCase().includes("phone")
  ) {
    contentId = moduleSectionIds["phone"];
  } else if (contentId.toLowerCase().includes("instagram")) {
    contentId = moduleSectionIds["instagram"];
  }
  logger.debug("contentId match after", contentId);

  const moduleContent = document.querySelectorAll<HTMLElement>(`#${contentId}`);
  for (const module of Array.from(moduleContent)) {
    const blockDisplay = module.style.display;
    if (blockDisplay === "none") {
      // logger.debug("display");
      module.style.display = "block";
      window.setTimeout(() => {
        module.style.opacity = "1";
        module.style.transform = "scale(1)";
      }, 0);
      if (module) {
        scrollToDestination("targetElement", module, "start");
      }
    } else {
      module.style.opacity = "0";
      module.style.transform = "scale(0)";
      window.setTimeout(() => {
        module.style.display = "none";
      }, 700);
    }
  }
}

/** *******
    Email Linked action
    * Push action number to the nested field linked
**********/
//TODO : Fix issue of "page possibly null", workaround seems to be done with pageOrId
export function loadActionUrl(
  pageOrId: HTMLElement | string,
  forms: NodeListOf<SIBElement> | NodeListOf<HTMLFormElement>,
) {
  let page: HTMLElement | null;
  // check if using id selector method
  logger.debug("want to display", pageOrId, typeof pageOrId);

  if (typeof pageOrId === "string") {
    if (pageOrId.includes("#")) {
      page = document.querySelector<HTMLElement>(pageOrId);
      logger.debug("query selector method", pageOrId);
    } else {
      throw new Error(
        `Invlid Parameter 'pageOrId' ${pageOrId}, should be an Id starting with #`,
      );
    }
  } else page = pageOrId;

  if (!forms) {
    forms = page?.querySelectorAll("solid-form") as NodeListOf<SIBElement>;
    logger.debug("no forms, selecting them all from the current page", forms);
  }

  if (!page) {
    return;
  }
  // get data src of first display of page
  let actionDataSource =
    page.querySelector<SIBElement>("solid-display")?.dataset.src ?? "";

  // issue https://git.startinblox.com/applications/risefor-front/-/issues/86
  // \r in data url pushed due to a %0D in url, breaks the action by updating the urlid with a space

  // logger.debug("action data src before",actionDataSource);
  actionDataSource = actionDataSource.replace("\r", "");

  logger.debug("action data src", actionDataSource);
  // push it to value in form
  if (!(forms instanceof NodeList)) {
    const form = forms as HTMLFormElement;
    const formActionField = form.querySelector("input[name*='linked']");
    // logger.debug("form field",formActionField);
    formActionField?.setAttribute(
      "value",
      JSON.stringify({ "@id": actionDataSource }),
    );
    const formName = form.getAttribute("name");
    logger.debug("name is", formName);
    if (formName?.includes("email-form")) {
      addChosenRepresentativesToEmail(form);
    }
  } else {
    forms.forEach((form) => {
      form = form as HTMLFormElement;
      const formActionField = form.querySelector("input[name*='linked']");
      // logger.debug("load url to form",form,formActionField);
      // formActionField.value = actionDataSource;
      // logger.debug("form field",formActionField);
      formActionField?.setAttribute(
        "value",
        JSON.stringify({ "@id": actionDataSource }),
      );
      const formName = form.getAttribute("name");
      logger.debug("name is", formName);
      if (formName?.includes("email-form")) {
        addChosenRepresentativesToEmail(form);
      }
    });
  }
}

/**
 * Customize content depending on target
 *  Replaces old content loader loadEmailContent() and loadTweet()
 *
 * @param {*} representativeFunction what type of representative are we targeting
 */
export function customizeRepresentativeContent(representativeFunction: string) {
  // logger.debug("representative",representativeFunction);
  // get different values possible & place them in case
  representativeFunction = representativeFunction.toLowerCase();
  if (
    representativeFunction.includes("déput") ||
    representativeFunction.includes("representative")
  ) {
    representativeFunction = "representative";
  } else if (
    representativeFunction.includes("sénat") ||
    representativeFunction.includes("senat")
  ) {
    representativeFunction = "senator";
  } else if (representativeFunction.includes("candi")) {
    representativeFunction = "candidate";
  } else if (representativeFunction.includes("manual")) {
    // logger.debug("manual targeting");
    representativeFunction = "manual";
  }
  loadTargetedRepresentativeEmails(representativeFunction);
  loadTargetedRepresentativeTwitter(representativeFunction);
  loadTargetedRepresentativePhone(representativeFunction);
}
/**
 * Customize email content
 * sub function started by customizeRepresentativeContent
 * @param {*} representativeFunction what type of representative are we targeting
 */
function loadTargetedRepresentativeEmails(representativeFunction: string) {
  // logger.debug("Loading emails for",representativeFunction);
  // needed vars
  let emailSubject = "";
  let emailContent = "";
  // get the content in the solid display
  switch (representativeFunction) {
    case "representative":
      emailSubject =
        document.querySelector('div[name="electedofficialEmailSubject"]')
          ?.textContent ?? "";
      emailContent =
        document.querySelector('div[name="electedofficialEmailText"]')
          ?.textContent ?? "";
      break;
    case "senator":
      emailSubject =
        document.querySelector('div[name="senatorEmailSubject"]')
          ?.textContent ?? "";
      emailContent =
        document.querySelector('div[name="senatorEmailText"]')?.textContent ??
        "";
      break;
    case "candidate":
      emailSubject =
        document.querySelector('div[name="candidateEmailSubject"]')
          ?.textContent ?? "";
      emailContent =
        document.querySelector('div[name="candidateEmailText"]')?.textContent ??
        "";
      break;
    case "manual":
      emailSubject =
        document.querySelector('div[name="targetedEmailSubject"]')
          ?.textContent ?? "";
      emailContent =
        document.querySelector('div[name="targetedEmailText"]')?.textContent ??
        "";
      break;

    default:
      break;
  }
  // get the fields in the form
  let emailForm: HTMLFormElement;
  if (representativeFunction === "manual") {
    emailForm = document.querySelector(
      "#manual-representative-email-send-form",
    )!;
  } else {
    emailForm = document.querySelector("#representative-email-send-form")!;
  }
  const subjectField = emailForm.querySelector<HTMLInputElement>(
    'input[name="subject"]',
  );
  const contentField = emailForm.querySelector<HTMLInputElement>(
    'textarea[name="content"]',
  );
  // logger.debug("email form is",emailForm,subjectField,contentField);
  // push the new content
  if (subjectField) subjectField.value = emailSubject.trim();
  if (contentField) contentField.value = emailContent.trim();
}

// Depreciation Warning
function loadTargetedRepresentativeTwitter(representativeFunction: string) {
  logger.warn("Depreciation warning for: loadTargetedRepresentativeTwitter()");
  // logger.debug("loading targeted twitter",representativeFunction);
  // vars needed
  let tweet1 = "";
  let tweet2 = "";
  let tweet3 = "";
  let tweet4 = "";
  // get the content depending on target type
  switch (representativeFunction) {
    case "representative":
      tweet1 =
        document.querySelector('solid-display-value[name="TweetMain"]')
          ?.textContent ?? "";
      tweet2 =
        document.querySelector('solid-display-value[name="Tweet2"]')
          ?.textContent ?? "";
      tweet3 =
        document.querySelector('solid-display-value[name="Tweet3"]')
          ?.textContent ?? "";
      tweet4 =
        document.querySelector('solid-display-value[name="Tweet4"]')
          ?.textContent ?? "";
      break;
    case "senator":
      tweet1 =
        document.querySelector('solid-display-value[name="senatorTweetMain"]')
          ?.textContent ?? "";
      tweet2 =
        document.querySelector('solid-display-value[name="senatorTweet2"]')
          ?.textContent ?? "";
      tweet3 =
        document.querySelector('solid-display-value[name="senatorTweet3"]')
          ?.textContent ?? "";
      tweet4 =
        document.querySelector('solid-display-value[name="senatorTweet4"]')
          ?.textContent ?? "";
      break;
    case "candidate":
      tweet1 =
        document.querySelector('solid-display-value[name="candidateTweetMain"]')
          ?.textContent ?? "";
      tweet2 =
        document.querySelector('solid-display-value[name="candidateTweet2"]')
          ?.textContent ?? "";
      tweet3 =
        document.querySelector('solid-display-value[name="candidateTweet3"]')
          ?.textContent ?? "";
      tweet4 =
        document.querySelector('solid-display-value[name="candidateTweet4"]')
          ?.textContent ?? "";
      break;

    case "manual":
      tweet1 =
        document.querySelector('solid-display-value[name="targetedTweetMain"]')
          ?.textContent ?? "";
      tweet2 =
        document.querySelector('solid-display-value[name="targetedTweet2"]')
          ?.textContent ?? "";
      tweet3 =
        document.querySelector('solid-display-value[name="targetedTweet3"]')
          ?.textContent ?? "";
      tweet4 =
        document.querySelector('solid-display-value[name="targetedTweet4"]')
          ?.textContent ?? "";
      break;

    default:
      break;
  }
  // display content in html
  let twitterSection: HTMLElement | null;
  if (representativeFunction === "manual") {
    twitterSection = document.querySelector("#manual-twitter-module");
  } else {
    twitterSection = document.querySelector("#twitter-module");
  }
  if (!twitterSection) {
    return;
  }
  const tab1 = twitterSection.querySelector("div#tab-1 p");
  if (tab1) tab1.textContent = tweet1;
  const tab2 = twitterSection.querySelector("div#tab-2 p");
  if (tab2) tab2.textContent = tweet2;
  const tab3 = twitterSection.querySelector("div#tab-3 p");
  if (tab3) tab3.textContent = tweet3;
  const tab4 = twitterSection.querySelector("div#tab-4 p");
  if (tab4) tab4.textContent = tweet4;
}

/**
 * Display content of phone call
 * @param {*} representativeFunction what type of representative are we targeting
 */
function loadTargetedRepresentativePhone(representativeFunction: string) {
  // rename 'manual' targeting to match query selectors
  if (representativeFunction === "manual") {
    representativeFunction = "targeted";
  }
  // logger.debug("load phone info",representativeFunction)
  // select all content displayed
  const allTargetedRepContent = document.querySelectorAll<SIBElement>(
    "solid-display[name=call-representatives-text] solid-display-div[name*=Objective],solid-display[name=call-representatives-text] solid-display-div-multiline[name*=Argumentation]",
  );

  // logger.debug("all targetted blocks",allTargetedRepContent);
  // for each section, check if it's related to the selected
  // representative of not; If not, hide, or else display
  for (const repContent of Array.from(allTargetedRepContent)) {
    // logger.debug("checking block", repContent, repContent.attributes.name.value);
    if (
      repContent &&
      !repContent.attributes
        .getNamedItem("name")
        ?.value.includes(representativeFunction)
    ) {
      repContent.style.display = "none";
    } else {
      repContent.style.display = "block";
      logger.debug("block is to be displayed", repContent);
    }
  }
}

/**
 * Created for openMailBox()
 * Make sure only attack 1 event listener
 */
function addSingleEventListener<X extends (e: Event) => void>(
  element: HTMLElement,
  eventType: string,
  eventHandler: X,
) {
  const el = element as HTMLElement & { listeners: Record<string, X> };
  // Check if an event listener of the same type is already attached
  if (el.listeners && el.listeners[eventType]) {
    // Remove the existing event listener
    el.removeEventListener(eventType, el.listeners[eventType]);
  }

  // Add the new event listener
  el.addEventListener(eventType, eventHandler);

  // Store the event listener in a custom property of the element
  if (!el.listeners) {
    el.listeners = {};
  }
  el.listeners[eventType] = eventHandler;
}
import { copyElement } from "./risefor";

/**
 * Feature https://git.risefor.org/applications/risefor-front/-/issues/125
 * Allow users to send with their own email box
 *          -> Using device app
 *          -> Opening in navigator
 * @param {HTMLElement} clicLocation - Button where was the event called
 */

export function openMailBox(clicLocation: HTMLElement) {
  let subject =
    clicLocation.parentElement?.parentElement?.querySelector<HTMLInputElement>(
      "solid-form input[name=subject]",
    )?.value ?? "";
  let content =
    clicLocation.parentElement?.parentElement?.querySelector<HTMLTextAreaElement>(
      "solid-form textarea[name=content]",
    )?.value ?? "";
  let emailto =
    clicLocation.parentElement?.parentElement?.querySelector<HTMLInputElement>(
      "solid-form input[name=representativeEmails]",
    )?.value ?? "";
  const newsletterOptin =
    clicLocation.parentElement?.parentElement?.querySelector<HTMLInputElement>(
      "solid-form solid-form-checkbox[name=optin2] input",
    )?.checked;
  const currenUrl = window.location.href;
  let newsletterStatus = false;
  let userName = "";
  let userEmail = "";
  if (newsletterOptin === true) {
    newsletterStatus = true;
    userName =
      clicLocation.parentElement?.parentElement?.querySelector<HTMLInputElement>(
        "solid-form input[name=userName]",
      )?.value ?? "";
    userEmail =
      clicLocation.parentElement?.parentElement?.querySelector<HTMLInputElement>(
        "solid-form input[name=userEmail]",
      )?.value ?? "";
  }

  logger.debug("email content", subject, content);
  subject = encodeURIComponent(subject);
  const contentNoEncode = content;
  content = encodeURIComponent(content);
  emailto = encodeURIComponent(emailto);

  const modalEmailBox1 = document.getElementById("open-email-box_1");
  // display it
  displayModal("open-email-box_1");

  // and listen to clicks for next steps
  const openApp = modalEmailBox1?.querySelector<HTMLElement>("#open-email-app");
  const openInNav =
    modalEmailBox1?.querySelector<HTMLElement>("#open-email-nav");

  if (openApp)
    addSingleEventListener(openApp, "click", (openAppEvent: MouseEvent) => {
      openAppEvent.stopPropagation();
      openAppEvent.preventDefault();
      const mailtoLink = `mailto:${emailto}?subject=${subject}&body=${content}`;
      // copyElement('emailContent',contentNoEncode)

      // modalOption.removeEventListener("click", uniqueEvent)
      window.location.href = mailtoLink;
      // add +1 model count here
      addToEmailCounter(currenUrl);
      // add check for newsletter signup
      if (newsletterStatus) {
        addToNewsletterList(userName, userEmail); // , currenUrl);
        // if can't send a navigate to sib, just:
        // window.location.href = "https://example.com/page";
      }
      // navigate to confirmation view
      const nextButton = modalEmailBox1?.querySelector<HTMLElement>(
        "#confirmation-contact-personnal",
      );
      if (modalEmailBox1) modalEmailBox1.style.display = "none";
    //   setTimeout(() => {
    //     nextButton?.click();
    //   }, 2000);
    });
  if (openInNav)
    addSingleEventListener(openInNav, "click", (openAppNav) => {
      openAppNav.stopPropagation();
      openAppNav.preventDefault();
      if (modalEmailBox1) modalEmailBox1.style.display = "none";
      displayModal("open-email-box_2");
      const modalEmailBox2 = document.getElementById("open-email-box_2");
      const listEmailProviders =
        modalEmailBox2?.querySelectorAll<HTMLButtonElement>(
          "#list-mailbox-providers button",
        );
      // sleep(3000);
      // setTimeout(() => {
      // 	copyElement('emailContent',contentNoEncode)
      // }, 1000);
      // Define a function to handle listEmailProvider click events
      function handleListEmailProviderClick(
        listEmailProvider: HTMLButtonElement,
      ) {
        return (listEvent: MouseEvent) => {
          listEvent.stopPropagation();
          listEvent.preventDefault();
          logger.debug(listEmailProvider.id);
          if (listEmailProvider.id === "other") {
            logger.debug("Email - click other");
            const model_step2 = document.getElementById("open-email-box_2");
            if (model_step2) model_step2.style.display = "none";
            displayModal("open-email-box_other");
          } else {
            openChosenService(
              listEmailProvider.id,
              emailto,
              subject,
              contentNoEncode,
              content,
              currenUrl,
              !!newsletterOptin,
              userName,
              userEmail,
              modalEmailBox2!,
            );
          }
        };
      }

      // Add event listeners to listEmailProviders
      if (listEmailProviders) {
        for (const listEmailProvider of Array.from(listEmailProviders)) {
          addSingleEventListener(
            listEmailProvider,
            "click",
            handleListEmailProviderClick(listEmailProvider),
          );
        }
      }
    });
}

/**
 * Created for openMailBox()
 * Onclick of service button in modal displaying services
 * Open to chosen email service
 */
function openChosenService(
  service: string,
  emailto: string,
  subject: string,
  contentNoEncode: string,
  content: string,
  currenUrl: string,
  newsletterStatus: boolean,
  userName: string,
  userEmail: string,
  modalEmailBox2: HTMLElement,
) {
  service = service.replace("Open-", "").toLowerCase();
  const contentLength = content.length;
  logger.debug("service is", service);
  let mailtoLink;
  switch (service) {
    case "gmail":
      //when longer that that it breaks
      if (contentLength < 4800) {
        mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${emailto}&su=${subject}&body=${content}`;
      } else {
        mailtoLink = `https://mail.google.com/mail/?view=cm&fs=1&to=${emailto}&su=${subject}`;
      }
      break;
    case "hotmail":
      if (contentLength < 4800) {
        mailtoLink = `https://outlook.live.com/owa/?path=/mail/action/compose&to=${emailto}&subject=${subject}&body=${content}`;
      } else {
        mailtoLink = `https://outlook.live.com/owa/?path=/mail/action/compose&to=${emailto}&subject=${subject}}`;
      }
      break;
    case "yahoo-mail":
      mailtoLink = `https://compose.mail.yahoo.com/?to=${emailto}&subject=${subject}`;
      break;
    // case 'aol-mail':
    //     mailtoLink = 'https://mail.aol.com/webmail-std/en-us/compose-message?to=' + emailto + '&subject=' + subject + '&body=' + content;
    //     break;
    case "mail-com":
      mailtoLink = `https://mail.com/email/writeemail/?to=${emailto}&subject=${subject}&body=${content}`;
      break;
    case "protonmail":
      // mailtoLink = `https://mail.protonmail.com/compose?to=${emailto}&subject=${subject}&body=${content}`;
      mailtoLink = `https://mail.protonmail.com/compose?to=${emailto}&subject=${subject}`;
      break;
    case "icloud":
      mailtoLink = `https://www.icloud.com/#mail/new?to=${emailto}&subject=${subject}`;
      break;
    // Add more services as needed
    case "orange":
      mailtoLink = `https://webmail.orange.fr/webmail/fr_FR/writemail?to=${emailto}&subject=${subject}`;
      break;
    case "laposte":
      mailtoLink = `https://webmail.laposte.net/mail#/?to=${emailto}&subject=${subject}`;
      break;
    case "sfr":
      mailtoLink = `https://webmail.sfr.fr/webmail/fr_FR/writemail?to=${emailto}&subject=${subject}`;
      break;
    case "free":
      mailtoLink = `https://imp.free.fr/horde/imp/compose.php?to=${emailto}&subject=${subject}`;
      break;
    default:
      console.error("Invalid service");
      return;
  }

  window.open(mailtoLink, "_blank");
  // add event to counter
  addToEmailCounter(currenUrl);
  // add to newsletter
  if (newsletterStatus) {
    addToNewsletterList(userName, userEmail); // , currenUrl);
  }
  // navigate to confirmation view
  const nextButton = modalEmailBox2.querySelector<HTMLElement>(
    "#confirmation-contact-personnal",
  );
  /*
	BELOW CLICKS TO CONFIRMATION PAGE
	TODO CHANGE BEHAVIOR TO CONFIRMATION MODAL, SO THAT USER STAYS ON PAGE
	*/
  // modalEmailBox2.style.display = 'none';
  // setTimeout(() => {
  // 	nextButton?.click();
  // }, 1000);
}

/** **********
 * Event send click info to counter
 * https://git.risefor.org/applications/risefor-front/-/issues/113
 ************/
function addToEmailCounter(url: string) {
  // Federation Improvement needed - https://git.risefor.org/applications/risefor-front/-/issues/144
  fetch("/email-sent-counter/", {
    method: "POST",
    headers: {
      // 'X-CSRFToken': getCookie('csrftoken'),
    },
    body: JSON.stringify({
      currentUrl: url, // Pass the current URL in the body
    }),
  })
    .then((response) => {
      if (response.ok) {
        logger.debug("Email Sender - Sent via personnal box");
      } else {
        console.error("Failed to increment Email Sender ia personnal box");
      }
    })
    .catch((error) => {
      console.error("Email Sender - An error occurred:", error);
    });
}
/** **********
 * Created for https://git.risefor.org/applications/risefor-front/-/issues/125
 * add optins to newsletter
 ************/

function addToNewsletterList(
  userName: string,
  userEmail: string,
  // currenUrl: string,
) {
  logger.debug("here, we will add to newsletter");
  const payload = {
    "@context": {
      "@vocab": "http://happy-dev.fr/owl/#",
      rdf: "http://www.w3.org/1999/02/22-rdf-syntax-ns#",
      rdfs: "http://www.w3.org/2000/01/rdf-schema#",
      ldp: "http://www.w3.org/ns/ldp#",
      foaf: "http://xmlns.com/foaf/0.1/",
      name: "rdfs:label",
      acl: "http://www.w3.org/ns/auth/acl#",
      permissions: "acl:accessControl",
      mode: "acl:mode",
      geo: "http://www.w3.org/2003/01/geo/wgs84_pos#",
      lat: "geo:lat",
      lng: "geo:long",
    },
    fullname: userName,
    userEmail: userEmail,
    signedUpFrom: "Sent Self Service",
    optin: "true",
  };

  const jsonLdPayload = JSON.stringify(payload);
  fetch("/newsletters/", {
    method: "POST",
    headers: {
      "Content-Type": "application/ld+json",
      // 'X-CSRFToken': getCookie('csrftoken'),
    },
    body: jsonLdPayload,
  })
    .then((response) => {
      if (response.ok) {
        logger.debug("Sent Self Service : Added to Newsletter ");
      } else {
        console.error("Failed Sent Self Service : Failed to Newsletter");
      }
    })
    .catch((error) => {
      console.error("An error occurred:", error);
    });
}
