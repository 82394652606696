// TS CHECK OFF -> For usage of 'sibstore'
// @ts-nocheck
import {
	asyncQuerySelector,
	asyncQuerySelectorAll,
} from 'async-query-selector-all/dist/async-query-selector';

import { hideContactRepresentativeModules } from './contact-representatives';
// import type { ComponentInterface } from '@startinblox/core/types/libs/interfaces'; // TODO figure out types from SIB
import logger from './logger';
import {
	getMobileOperatingSystem,
	openLinkInApps,
	openLinksInNav,
} from './open-outbound-links';
import type { LaunchType, SIBElement, SIBRouterEvent } from './types';
import { filterNullKeys } from './utils';

/** ******************
   
********************/

export function launchLoadingScripts(launchType: 'auto' | undefined) {
	if (launchType === undefined) {
		launchType = 'auto';
	}
	setTimeout(() => {
		logger.debug('dom loaded + timeout');
		const destination = document.location.pathname;

		socialMediaShare(null, 'DOMContentLoaded');
		/**
		 * Action group scripts
		 *    TODO: REVIEW PLACEMENT AS IS LAUNCHED EVERYWHERE
		 **/
		scriptLauncherActions(destination, launchType);
		refreshSolidMapSrc();
		
		// Listen for language switches
		const languageForm = document?.querySelector('.language-switch');
		logger.debug('presence of language switch?', languageForm);
		if (languageForm) {
			manageSelectLanguage(/* languageForm */);
		}
		/** ********
		 * navigate listener to launch scripts
		 ***********/
		// scroll to top on navigate
		document.addEventListener('navigate', (e) => {
			logger.debug('navigating');
			e.preventDefault();
			e.stopPropagation();
			e.stopImmediatePropagation();
			setTimeout(() => {
				logger.debug('first router nav scroll top', e);
				window.scrollTo({ top: 0 });
			}, 0);
		});
		/* Action pages listeners */
		if (document.location.pathname.includes('action')) {
			logger.debug('action page navigate listener');

			const actionPageRouter = document.querySelector(
				"solid-router[id='action-groups-router']",
			);
			actionPageRouter?.addEventListener('navigate', (e: SIBRouterEvent) => {
				logger.debug('action page nav');
				hideContactRepresentativeModules(document.body);
				if (e.type != 'DOMContentLoaded') {
					const destination = e.detail.route;
					logger.debug('action page nav not dom');
					// if action group page;
					const route = e.detail.route;
					if (route.includes('action-group-detail')) {
						logger.debug(
							'action page ${route} - launching scriptLauncherActions() ',
						);
						/**
						 * Action group scripts
						 **/
						setTimeout(() => {
							scriptLauncherActions(destination);
							refreshSolidMapSrc();
						}, 5);
					}
				}
			});
		}
	}, 500);
}


/** **********
 * Choose consultation texte within proposed ones
 * @param {HTMLElement} location of the clic to make the choice
 ************/
export function consultationChooseOption(argumentaire) {
	const customizationForm = document.querySelector<HTMLFormElement>(
		'solid-form[name=consultationStep__customize] textarea',
	);
	logger.debug('target form', customizationForm, customizationForm?.value);
	if (customizationForm) customizationForm.value = argumentaire;
}

/** **********
 * scroll to somewhere
 * @param {string} scroll to target or querySelector needed
 * @param {html} where to scroll
 * * @param {string} where the destination should be on navigator (start, center, end,.. )
 ************/
export function scrollToDestination(
	targetType: 'targetElement',
	destinationIdOrNode: HTMLElement,
	blockDestination: ScrollLogicalPosition,
	addOffset?: number,
): void;
export function scrollToDestination(
	targetType: 'targetID',
	destinationIdOrNode: string,
	blockDestination: ScrollLogicalPosition,
	addOffset?: number,
): void;
export function scrollToDestination(
	...[targetType, destinationIdOrNode, blockDestination, addOffset]: [
		...args: [
			targetType: 'targetElement' | 'targetID',
			destinationIdOrNode: HTMLElement | string,
			blockDestination: ScrollLogicalPosition,
			addOffset?: number,
		],
	]
) {
	if (destinationIdOrNode) {
		setTimeout(() => {
			if (!addOffset) {
				addOffset = 0;
			}
			if (!blockDestination) {
				blockDestination = 'center';
			}
			logger.debug('scroll to destination', targetType, destinationIdOrNode);
			// element to navigate to is given
			if (targetType === 'targetElement') {
				(destinationIdOrNode as HTMLElement).scrollIntoView({
					behavior: 'smooth',
					block: blockDestination,
					// offset: addOffset, property does not seem to exist
				});
			}
			// ID of element givent query selector needed
			else if (targetType === 'targetID') {
				const d = document.querySelector(`#${destinationIdOrNode}`);
				if (!d) {
					return;
				}
				logger.debug('destination is', destinationIdOrNode);
				d.scrollIntoView({
					behavior: 'smooth',
					block: blockDestination,
					// offset: addOffset, property does not seem to exist
				});
			}
		}, 50);
	}
}

/** *********************
  Action Page launchers 
  * @param {event} e - DOMLoad or Navigation event launching the scripts 
  * @param {string} destination  - URI of page
***********************/
export async function scriptLauncherActions(
	destination: string,
	launchType?: LaunchType,
) {
	if (launchType === undefined) {
		launchType = 'auto';
	}
	logger.debug(
		'action page script launcher',
		launchType,
		'destination is',
		destination,
	);
	// rename destination for queryselector
	destination = 'action-group-detail-home';
	/**
	 * VISIBILITY CONTROLER
	 */
	// get all elements that have visiblity controler
	const visibilitySections = document.querySelectorAll(
		`#${destination} .visibility-controler`,
	) as NodeListOf<HTMLElement>;

	// logger.debug("visibility sections",visibilitySections);
	for await (const visibilitySection of asyncQuerySelectorAll(
		`#${destination} .visibility-controler`,
		document,
	)) {
		const visibilitySolidDisplay = visibilitySection.querySelector(
			'solid-display',
		) as SIBElement;
		logger.debug('async visibility solid display', visibilitySolidDisplay);
		const parentSection = visibilitySolidDisplay?.closest('section');
		if (!parentSection || !visibilitySolidDisplay) return;
		const parentSectionId = parentSection?.id;
		if (launchType === 'manual') {
			if (parentSectionId === 'representative-targetting-type') {
				logger.debug('Display targetting bloc', parentSection);
				visibilityControlerTargettedMethod(parentSection);
			} else if (
				visibilitySolidDisplay.classList.value.includes('display-action-step')
			) {
				// logger.debug("action step",visibilitySection);
				// visibilityControlerActionStep(visibilitySection); // REPLACING WITH CSS .css-visibility-controler
				// logger.debug("action step styling",visibilitySolidDisplay);
			} else {
				visibilityControler(visibilitySolidDisplay, parentSection);
			}
		}

		// ISSUE with depending on populate event below
		// > When navigator loads fast, the populate event has already passed

		// Bit of a crappy fix  below
		// 1. check if content has loaded
		// 2. lauch the needed scripts, else do listen for pupulate
		if (visibilitySolidDisplay?.textContent?.trim() !== '') {
			// logger.debug("Visi check - populate passed",visibilitySolidDisplay);
			if (parentSectionId === 'representative-targetting-type') {
				// logger.debug("Display targetting bloc",parentSection);
				visibilityControlerTargettedMethod(parentSection);
			} else if (
				visibilitySolidDisplay.classList.value.includes('display-action-step')
			) {
				// logger.debug("action step",visibilitySection);
				// visibilityControlerActionStep(visibilitySection); // REPLACING WITH CSS .css-visibility-controler
				// logger.debug("action step styling",visibilitySolidDisplay);
				// styleActiveActionStep(visibilitySolidDisplay); // REPLACING WITH CSS .css-visibility-controler
			} else {
				visibilityControler(visibilitySolidDisplay, parentSection);
			}
		} else {
			visibilitySolidDisplay.addEventListener('populate', () => {
				logger.debug(
					'Visibility check - populate method',
					visibilitySolidDisplay,
				);
				if (parentSectionId === 'representative-targetting-type') {
					logger.debug('Display targetting bloc', parentSection);
					visibilityControlerTargettedMethod(parentSection);
				} else if (
					visibilitySolidDisplay.classList.value.includes('display-action-step')
				) {
					// visibilityControlerActionStep(visibilitySection); // REPLACING WITH CSS .css-visibility-controler
					// logger.debug("action step styling",visibilitySolidDisplay);
					// styleActiveActionStep(visibilitySolidDisplay); // REPLACING WITH CSS .css-visibility-controler
				} else {
					visibilityControler(visibilitySolidDisplay, parentSection);
				}
			});
		}
	}
}

function getRndInteger(min: number, max: number) {
	return Math.floor(Math.random() * (max - min + 1)) + min;
}

/* #####################################################
  ***** GLOBAL SCRIPTS *****
#####################################################*/

/** ******************
    On click of parent it show/hides child 
********************/
export function showHideBlocks(
	parentBlock: string,
	childBlock: string,
	style: CSSStyleDeclaration['display'],
	blockHidding?: boolean,
) {
	if (!blockHidding) {
		blockHidding = false;
	}
	const contentToDisplay = document.querySelectorAll(
		childBlock,
	) as NodeListOf<HTMLElement>;
	for (let i = 0; i < contentToDisplay.length; i++) {
		// change display
		if (
			contentToDisplay[i].style.display === 'none' ||
			contentToDisplay[i].style.display === ''
		) {
			contentToDisplay[i].style.display = style;
		} else if (blockHidding) {
			logger.debug('not hidding', blockHidding);
			return;
		} else {
			contentToDisplay[i].style.display = 'none';
		}
	}
}

/** **********
 * Display a target element
 * @param {*} clickElement element clic location used to update text
 * @param {*} targetElement element to display
 * @param {*} style wanted style
 * @param {*} newText optional - if we want to change the text of the button
 ************/
export function displayElement(
	clickElement: HTMLElement,
	targetElementId: string,
	style: CSSStyleDeclaration['display'],
	newText?: string,
) {
	logger.debug('display element', clickElement, targetElementId);
	// display element
	const el = document.querySelector(targetElementId) as HTMLElement;
	if (el == null) throw new Error(`Unknown Element ${targetElementId}`);
	logger.debug(el, el.style.display);
	el.style.display = style;

	if (newText) {
		const clickText = clickElement.innerText;
		logger.debug("there's a new text wanted", newText, clickText);
		(clickElement.lastElementChild as HTMLElement).innerText = newText;
	}
}

/** **********
 * Hide a target element
 * @param {*} target element to hide
 * @param {*} display new style (default == none)
 * @param {*} wantedTimeout Add timeout to script, useful for solid-link
 ************/
export function hideElement(
	target: string,
	display?: CSSStyleDeclaration['display'],
	wantedTimeout?: number,
) {
	const displayStyle = !display || display === '' ? 'none' : display;
	if (!wantedTimeout) {
		wantedTimeout = 0;
	}
	setTimeout(() => {
		const el = document.querySelector(target) as HTMLElement;
		if (el) el.style.display = displayStyle;
	}, wantedTimeout);
}

/** **********
 * Copy element's value to clipboard
 * @param {string} type of element to copy
 * @param {HMTLElment | string} elementOrValue to copy
 ************/
export function copyElement(
	...[type, elementOrValue]:
		| [type: 'default', value: string]
		| [type: 'consultationText', element: HTMLElement]
		| [type: 'formText', value: string]
		| [type: 'emailContent', value: string]
		| [type: 'emailContent_targetHtml', value: HTMLElement]
		| [type: 'selector', element: string]
) {
	//If it's an emailContent_targetHtml, get the value sting then treat it as emailContent
	if (type === 'emailContent_targetHtml') {
		// get representative solid forms on the current page
		const actionSolidForms = document.querySelectorAll(
			'solid-form[id*=representative-email] textarea[name=content]',
		);
		logger.debug('Copy Email HTML element', actionSolidForms);
		const visibleElement = HTMLTextAreaElement;
		for (const e of Array.from(actionSolidForms)) {
			const element = e as HTMLTextAreaElement;
			logger.debug(
				'Email visibility',
				element.offsetParent,
				'content is',
				element.value,
			);

			// Using "offsetParent" to get the form that is visible
			// (Improvement needed -> Only 1 sender form, no use of having 2)
			if (element.offsetParent !== null) {
				logger.debug(
					'Text chose, new value is',
					type,
					'relaunch function with text',
					element.value,
				);
				copyElement('emailContent', element.value);
			} else {
				return;
			}
		}
		// type = "emailContent"
	}
	logger.debug('after emailContent_targetHtml');
	if (type === 'consultationText') {
		try {
			logger.debug('copying consultation text, start', type, elementOrValue);
			const tousArgumentaire = elementOrValue
				.closest('div')
				?.querySelectorAll(
					'solid-display-div-multiline[name*=argumentaire]',
				) as NodeListOf<HTMLElement>;
			// FOR RANDOM, only select displayed arguments
			const argumentaire = Array.from(tousArgumentaire).find(
				(el) => el.style.display != 'none',
			);
			// copy element
			const storage = document.createElement('textarea');
			// storage.style.display = 'none';
			storage.value = argumentaire?.innerText ?? '';
			argumentaire?.appendChild(storage);
			logger.debug('argumentaire is', storage.value);

			// Copy the text in the fake `textarea` and remove the `textarea`
			storage.select();
			storage.setSelectionRange(0, 99999);
			document.execCommand('copy');
			navigator.clipboard.writeText(storage.value);
			argumentaire?.removeChild(storage);
			alert(`L'argumentaire a été copié ${storage.value}`);
			// Alert the copied text
		} catch (error) {
			alert(`Une erreur est survenu lors du copier/coller: ${error}`);
		}
	} else if (
		type === 'formText' ||
		type === 'default' ||
		type === 'emailContent' ||
		type === 'selector'
	) {
		// logger.info('copying element type', type, ' and element ', elementOrValue);
		let elementToCopy: HTMLInputElement | HTMLTextAreaElement;
		if (type === 'selector') {
			logger.debug('copy selector is :',document.querySelector(`${elementOrValue}`))
			elementToCopy = document.querySelector(
				`${elementOrValue}`,
			) as HTMLInputElement;
			elementToCopy.value = elementToCopy.innerText;
			// logger.debug('copying selector, result: ',elementToCopy)
		} else if (type !== 'formText') {
			elementToCopy = document.createElement('textarea');
			elementToCopy.value = elementOrValue;
			// logger.debug('in copy - original',elementOrValue)
			// logger.debug('in copy - input', elementToCopy.value)
		} else {
			elementToCopy = document.querySelector(
				`*[name=${elementOrValue}] *`,
			) as HTMLInputElement;
		}
		// logger.debug(elementToCopie,elementToCopie.innerText,elementToCopie.value);
		// copy element
		// logger.debug("copying",elementToCopy)
		const storage = document.createElement('textarea');
		// storage.style.display = 'none';
		storage.value = elementToCopy.value;

		elementToCopy.appendChild(storage);
		// logger.debug("argumentaire is",storage.value);

		// Copy the text in the fake `textarea` and remove the `textarea`
		storage.select();
		storage.setSelectionRange(0, 99999);
		document.execCommand('copy');
		navigator.clipboard.writeText(storage.value).then(
			() => {
				/* clipboard successfully set */
				if (type === 'formText' || type === 'selector') {
					window.alert(
						`Vous avez copié le texte suivant : \n ${storage.value}`,
					);
				} else if (type === 'emailContent') {
					window.alert(
						`Le contenu de l'email a été copié dans votre presse-papier.`,
					);
				} else {
					window.alert(`${storage.value} a bien été copié`);
				}
			},
			(e) => {
				logger.debug(e);
				//Extracting the name and message from the error object
				const errorName = e.name || '';
				if (errorName.includes('NotAllowedError')) {
					window.alert(
						`Risefor n'a pas la permission pour copier le texte dans votre presse-papier.\nVeuillez donner l'autorisation ou copier le contenu de l'email manuellement. \nLe détail de l'erreur :\n\n ${e}`,
					);
				} else {
					window.alert(
						`Il y a eu une erreur lors du copier, voici le détail :\n\n ${e}`,
					);
					/* clipboard write failed */
				}
			},
		);
		elementToCopy.removeChild(storage);
	}
}


/** *********************
  Refresh Solid Map Datasct
   {HTMLElement} appForm - Form linked to the map
***********************/
function refreshSolidMapSrc() {
	setTimeout(() => {
		// select cur  rent page
		const page = document.querySelector('#action-group-detail-home');
		// get map on current page
		const map = page?.querySelector('solid-map') as SIBElement | undefined;
		// if (map == null) throw new Error('Could not find solid map');
		if (map == null) logger.warn('Could not find solid map');
		refreshComponent(map);
	}, 1000);
}

function refreshComponent(component: SIBElement) {
	const dataSource = component?.getAttribute('data-src');
	logger.debug('refreshing component', component, 'data src', dataSource);

	// With federation, some datasources become "#"
	// exclude them
	if (dataSource) {
		if (dataSource !== '#') {
			const url = new URL(dataSource);
			const newUrl = `${url.origin + url.pathname}`; // + '?' + Math.random()
			// logger.debug("Refreshing component with url",url,url.href,'new url',newUrl);
			component.setAttribute('data-src', newUrl);
		}
	}
}

/* #####################################################
            ***** ACTION GROUPS *****
#####################################################*/

/* =
    * Social media share buttons 
 =*/
function socialMediaShare(_: unknown, eventSocialMediaShare: Event | string) {
	// logger.debug("start social media share");

	if (typeof eventSocialMediaShare !== 'string') {
		eventSocialMediaShare.stopPropagation();
		eventSocialMediaShare.preventDefault();
	}
	const shareButtons = document.querySelectorAll(
		'#share-bar a,#top-share-bar a',
	);
	/** *
	 * Detect device, if we're on mobile
	 * open dialog box & allow user to choose
	 * between opening in app or nav
	 ***/
	const device = getMobileOperatingSystem();
	let deviceIsMobile = false;
	if (device != 'unknown') {
		// logger.debug("we're on mobile",device);
		deviceIsMobile = true;
	}
	if (shareButtons) {

		for (const shareButton of Array.from(shareButtons)) {
				
			// logger.debug("share text",shareText)
			shareButton.addEventListener('click', (eventShareButton) => {
				// if action groups
				let shareTitle;
				let shareObjective;
				if (window.location.pathname.includes('action-group-detail')) {
					logger.debug('action page');
					// get objective to use as share text
					shareTitle = (
						document?.querySelector('[class=h1-like]') as
							| HTMLElement
							| undefined
					)?.innerText;
					if (shareTitle === undefined) {
						shareTitle = document?.querySelector('title')?.innerText;
					}
					shareObjective = document?.querySelector<HTMLElement>(
						'div[id=action-group-detail-home] solid-display[name=action-objective] div[name=objective]',
					)?.innerText;
					if (shareObjective === undefined) {
						shareObjective = (
							document?.querySelector('meta[name=description]') as
								| HTMLMetaElement
								| undefined
						)?.content;
					}
				} else {
					shareTitle = document.querySelector('title')?.innerText;
					shareObjective = document.querySelector<HTMLMetaElement>(
						'meta[name=description]',
					)?.content;
				}
				// logger.debug("share title",shareTitle,"objective",shareObjective);
				let shareText = `${shareTitle}.\n${shareObjective}`;

				// make just first letter a capital
				shareText = shareText.toLowerCase();
				shareText = shareText.charAt(0).toUpperCase() + shareText.slice(1);

				// encode texte
				shareText = encodeURI(shareText);
				// encode # seperately
				shareText = shareText.replace(/#/g, '%23');
				// set share url
				let currentPageUrl = document?.querySelector(
					'solid-display-value[name="shortLink.shortLink"]',
				)?.textContent;
				// handle cases where no short links
				if (currentPageUrl === location.origin) {
					currentPageUrl = location.href;
				} else if (
					!currentPageUrl ||
					currentPageUrl.includes('undefined')
				) {
					currentPageUrl = location.origin + location.pathname;
				}
				// remove anchor in URL, if there's one
				currentPageUrl = currentPageUrl.replace(/#\w+.*/, '');
				// logger.debug("click",shareButton);
				eventShareButton.stopPropagation();
				eventShareButton.preventDefault();

				// logger.debug("share button event is",eventShareButton,eventShareButton.bubbles);
				/** *
                 * Share platform needs to be determined here due to 
                 * unresolved issue.
                 * Workaround of issue resets plateform as null after click,
                 * To allow user to reclic without reloading, we need to keep it here
                 
                  *THE ISSUE: 
                  * if click on multiple, or close & pick another platform
                  * the events add up and openLinkInApps or InNav is launched for each
                  * time it was clicked
                */
				// select classes to determine which platform was used
				const classList = shareButton.classList;
				const socialClass = classList.value;
				// clean is and use as base for the switch
				let sharePlateform = socialClass.split('is-').join('');
				sharePlateform = sharePlateform.trim();
				logger.debug('share platform is', sharePlateform, shareText);
				if (deviceIsMobile === true && sharePlateform !== 'copy-link') {
					// logger.debug("copy is mobile or not copy link");
					displayDialogOpenAppOrNav(
						sharePlateform,
						shareText,
						currentPageUrl,
						null,
					);
				} else if (sharePlateform === 'copy-link') {
					copyElement('default', currentPageUrl);
				} else if (
					sharePlateform === 'twitter' ||
					sharePlateform === 'whatsapp' ||
					sharePlateform === 'facebook' ||
					sharePlateform === 'messenger' ||
					sharePlateform === 'telegram' ||
					sharePlateform === 'signal'
				) {
					// logger.debug("copy not mobile or copy link");
					openLinksInNav(sharePlateform, shareText, currentPageUrl);
					// logger.debug("after const",sharePlateform);
				}
			});
		};
	}
}

function displayDialogOpenAppOrNav(
	sharePlateform: string | null,
	shareText: string,
	currentPageUrl: string,
	interpellation: string | null,
) {
	// select dialog & display it
	const dialog = document.querySelector<HTMLElement>('#choose-app-navigator');
	logger.debug('dialog is', dialog);
	if (!dialog) return;
	dialog.style.display = 'flex';
	// listen for clicks
	const dialogButtons = dialog.querySelectorAll('#app,#nav,div#close');
	// logger.debug("dialog buttons are",dialogButtons);
	for (const dialogButton of Array.from(dialogButtons)) {
		dialogButton.addEventListener('click', (dialogClickEvent) => {
			dialogClickEvent.stopPropagation();
			dialogClickEvent.preventDefault();

			const clickElement = (dialogClickEvent.target as HTMLElement).id;
			// check clic types
			if (
				sharePlateform === 'twitter' ||
				sharePlateform === 'whatsapp' ||
				sharePlateform === 'facebook' ||
				sharePlateform === 'telegram' ||
				sharePlateform === 'signal' ||
				sharePlateform === 'messenger'
			) {
				if (clickElement === 'app') {
					openLinkInApps(sharePlateform, shareText, currentPageUrl);
					// make null orelse keeps if click on multiple
					// platforms one after another the event is launched multiple times
					sharePlateform = null;
					if (interpellation) {
						addToPlateformCounter(window.location.href);
					}
				} else if (clickElement === 'nav') {
					openLinksInNav(sharePlateform, shareText, currentPageUrl);
					sharePlateform = null;
					if (interpellation) {
						addToPlateformCounter(window.location.href);
					}
				}
			} else if (clickElement === 'close' || clickElement === '') {
				sharePlateform = null;
				// logger.debug("chose to close, clear event",sharePlateform);
			}
			// if don't hide, then sharePlateform stays Null
			// next clicks
			dialog.style.display = 'none';
		});
	}
	// listen for close click on dialog box
	const dialogClose = dialog.querySelector('.close');
	dialogClose?.addEventListener('click', () => {
		// dialog.removeAttribute('open');
		dialog.style.display = 'none';
	});
}

function getHostElement(element: HTMLElement): HTMLElement | null {
    const root = element.getRootNode();
    if (root instanceof ShadowRoot) {
        return root.host as HTMLElement; // Return the host element of the shadow DOM
    }
    return null; // Element is not in a shadow DOM
}

/** *********************
 *Tweet message to elected official
 ***********************/
export function TwitterEOMultipleContentOptions(clickLocation: HTMLElement) {
	// logger.debug("location of click",clickLocation);
	const HomeUrl = location.href;
	let representativeTwitterAccounts = '';
	// get the displays of all representative above the click
	const hostElement = getHostElement(clickLocation);

	const representativeDisplays = hostElement
		?.closest('#twitter-multiple-choices,#manual-contact-content-twitter')
		?.querySelector(
			'#representatives-search-module > solid-display,u4e-targeted-representative-widget > solid-display',
		)
		?.querySelectorAll(
			':scope > div > solid-display',
		) as NodeListOf<HTMLElement>;
	if (representativeDisplays == null)
		throw new Error('Could not find representatives Solid Display');
	// logger.debug("representative displays",representativeDisplays);
	// check if any representatives are selected
	let checkedItems = 0;
	for (const representativeDisplay of Array.from(representativeDisplays)) {
		// logger.debug("current display",representativeDisplay);
		// count the number of checked items
		const box = representativeDisplay?.querySelector<HTMLInputElement>(
			'input[type=checkbox]',
		)?.checked;
		// logger.debug("representative box",box);
		if (box === true) {
			checkedItems = checkedItems + 1;
		}
	}

	for (const representativeDisplay of Array.from(representativeDisplays)) {
		// see if the representative is selected
		const selectedRepresentative = (
			representativeDisplay?.querySelector('input[type=checkbox]') as
				| HTMLInputElement
				| undefined
		)?.checked;
		if (selectedRepresentative === true) {
			// logger.debug("checked representative",selectedRepresentative);
			// get the twitter accounts
			const twitterAccounts = representativeDisplay.querySelectorAll(
				'solid-multiple-div[name=twitterAccount] solid-display-value[name=name]',
			);
			// logger.debug("accounts",twitterAccounts);
			// for each account, add it to the list
			for (const el of Array.from(twitterAccounts)) {
				let twitterAccount = el.textContent;
				if (twitterAccount == null) return; // TODO throw ?
				// if it's a twitter url, clean it
				if (twitterAccount.includes('https')) {
					twitterAccount = twitterAccount
						.split('https://twitter.com/')
						.join('@');
					twitterAccount = twitterAccount.split('https://x.com/').join('@');
					twitterAccount = twitterAccount.replace('@@', '@');
				} else if (!twitterAccount.includes('@') && twitterAccount !== '') {
					// logger.debug("does not contain an '@'",twitterAccount);
					twitterAccount = `@${twitterAccount}`;
				}
				if (!representativeTwitterAccounts.includes(twitterAccount)) {
					representativeTwitterAccounts = `${representativeTwitterAccounts} ${twitterAccount}`;
				}
			}
		}
	}

	// get chosen content
	const shadowRoot = clickLocation?.getRootNode();
	logger.debug(`shaddow root is ${clickLocation} ${shadowRoot}`)
	let SelectedTwitterContent = (
		shadowRoot?.querySelector('.tabs-stage') as
			| HTMLElement
			| undefined
	)?.innerText;
	logger.debug(`twitter content is ${SelectedTwitterContent}`)
	/** IF below for transition to depreciation
	 *
	 * Evolution to use of risefor-display-tabs
	 * Only need to get the element that is displayed as system has changed
	 * old way depends on 'tab-active' class to differenciate
	 * new way updates the dom, no need to defferenciate
	 **/
	if (SelectedTwitterContent === undefined) {
		SelectedTwitterContent =
			(
				clickLocation?.parentElement?.querySelector(
					'.tabs-stage .tab-active',
				) as HTMLElement | undefined
			)?.textContent ?? '';
	}

	logger.debug('chosen twitter accounts are', representativeTwitterAccounts);
	representativeTwitterAccounts = representativeTwitterAccounts
		.split(' undefined')
		.join('');
	// Current URL added in alleo_account if no account found, so here we clean it
	representativeTwitterAccounts = representativeTwitterAccounts
		.split(HomeUrl)
		.join('');

	// logger.debug("all tweets",SelectedTwitterContent,representativeTwitterAccounts);
	/**
	 * If there's the content "@CompteACibler" in content then place tags there
	 */
	let tweet = '';
	if (
		SelectedTwitterContent?.toLowerCase()?.includes('compteacibler') ||
		SelectedTwitterContent?.toLowerCase()?.includes('chosenaccounts')
	) {
		SelectedTwitterContent = SelectedTwitterContent.replace(
			'@compteacibler',
			'@CompteACibler',
		).replace('@chosenaccounts', '@CompteACibler');
		// logger.debug("tweet is",SelectedTwitterContent);
		tweet = SelectedTwitterContent.split('@CompteACibler').join(
			representativeTwitterAccounts,
		);
		// logger.debug("compte à cibler dans le text",tweet);
	} else {
		// now build full tweet
		tweet = `${SelectedTwitterContent} ${representativeTwitterAccounts}`;
	}
	// remove multiple spaces & backspaces
	tweet = tweet.replace(/\n/g, ' ');
	tweet = tweet.replace(/\s+/g, ' ');

	/**
	 * Set current url
	 */
	// get shortlink
	let currentPageUrl = document?.querySelector(
		'solid-display-value[name="shortLink.shortLink"]',
	)?.textContent;
	// logger.debug("twitter url is",currentPageUrl)
	// handle cases where no short links

	if (currentPageUrl === location.origin) {
		currentPageUrl = location.origin + location.href;
	} else if (!currentPageUrl || currentPageUrl.includes('undefined')) {
		currentPageUrl = location.origin + location.pathname;
	}
	// remove anchor in URL, if there's one
	currentPageUrl = currentPageUrl.replace(/#\w+.*/, '');
	// encode it

	/*
    Uncomment below to display URL in tweet 
    */
	// tweet += "\n"+currentPageUrl

	const sharableTweet = encodeURI(tweet).replace(/#/g, '%23');

	// get device

	// if mobile, give option to share in app
	const device = getMobileOperatingSystem();
	logger.debug('Twitter interpellation - device is', device);
	// logger.debug("device is",device)

	if (device !== 'unknown') {
		/*
         Uncomment below if want URL in interpellation tweet, then comment following line
        */
		// displayDialogOpenAppOrNav('twitter',sharableTweet,currentPageUrl)
		displayDialogOpenAppOrNav('twitter', sharableTweet, '', 'interpellation');

		// logger.debug("open link, unknown ",device);
		// openLinkInApps('twitter',sharableTweet,'')
	} else {
		addToPlateformCounter(window.location.href);
		window.open(
			`https://twitter.com/intent/tweet?text=${sharableTweet}`,
			'_blank',
		);
		// logger.debug("open link, else",device);
		// share it
	}
}

/** *********************
    Customize chose representative fnction (rep, senators, candidates,...)
 ***********************/
export function representativeSearchUpdateFunction(clickLocation: HTMLElement) {
	// logger.debug("representative function update",clickLocation);
	const representativeSource = clickLocation
		?.closest('solid-display')
		?.getAttribute('data-src');
	const currentSection = clickLocation.closest('section');
	// let searchForm = currentSection.querySelector('solid-form-text[name=function]');

	// logger.debug(representativeSource,currentSection,"value is",currentSection.querySelector('solid-form-text[name=function] input').value);
	const input = currentSection?.querySelector<HTMLInputElement>(
		'solid-form-text[name=function] input',
	);
	if (representativeSource != null && input) input.value = representativeSource;

	// send onchange event7
	const form = currentSection?.querySelector('solid-form-search');
	const formId = form?.id;
	// let event = new Event('change');
	// logger.debug("sending form");
	// form.dispatchEvent(event);

	const linkedDisplays = document.querySelectorAll(
		`solid-display[filtered-by=${formId}]`,
	) as NodeListOf<SIBElement>;
	for (const linkedDisplay of Array.from(linkedDisplays)) {
		// logger.debug("search display, refreshing component",linkedDisplay)
		refreshComponent(linkedDisplay);
	}
}

/** *********************
    Select representatives on card click
 ***********************/
export function selectRepresentativeCardClick(representativeCard: HTMLElement) {
	// logger.debug("current card",representativeCard);
	const representativeCheckBox =
		representativeCard.querySelector<HTMLInputElement>('input[type=checkbox]');
	if (!representativeCheckBox) return;
	const representativeCheckBoxStatus = representativeCheckBox.checked;
	if (representativeCheckBoxStatus === true) {
		representativeCard.classList.remove('active');
		representativeCheckBox.checked = false;
	} else {
		representativeCard.classList.add('active');
		representativeCheckBox.checked = true;
	}
	// logger.debug("clicked box",representativeCard,representativeCheckBox.classList);
}
/**
 * Listen for click on search result cards
 * @param {*} module - wanted module
 * @param {*} clickLocation - location
 */
// listening function for selectRepresentativeCardClick
export function listenSelectRepresentativeCardClick(
	module: string,
	clickLocation: HTMLElement,
) {
	// logger.debug("click location",clickLocation);
	const currentSection = clickLocation.closest('section');
	// To avoid bug, hide any currently displayed module
	if (module.toLowerCase().includes('email')) {
		module = 'email';
	} else if (module.toLowerCase().includes('twitter')) {
		module = 'twitter';
	} else if (
		module.toLowerCase().includes('téléphone') ||
		module.toLowerCase().includes('phone')
	) {
		module = 'phone';
	} else if (module.toLowerCase().includes('instagram')) {
		module = 'instagram';
	}

	let sectionType = 'Global';
	if (currentSection?.id.includes('manual')) {
		sectionType = 'Manual';
	}
	let moduleSectionIds: Record<string, string> = {};
	if (sectionType === 'Manual') {
		moduleSectionIds = {
			email: 'manual-contact-content-email',
			twitter: 'manual-contact-content-twitter',
			phone: 'manual-contact-content-call',
			instagram: 'manual-contact-content-instagram',
		};
	} else {
		moduleSectionIds = {
			email: 'representative-contact-content-email',
			twitter: 'representative-contact-content-twitter',
			phone: 'representative-contact-content-call',
			instagram: 'representative-contact-content-instagram',
		};
	}
	// logger.debug("current section",currentSection);
	// logger.debug("section id",moduleSectionIds);
	for (const moduleSectionId in moduleSectionIds) {
		// logger.debug("section in for",currentSection);
		(
			currentSection?.querySelector(
				`#${moduleSectionIds[moduleSectionId]}`,
			) as HTMLElement
		).style.display = 'none';
	}
	// in the section, select the contact modules
	// based on 'representative-contact-*' in their id
	let moduleSection: HTMLElement | null | undefined = null;
	// logger.debug("before switch module",module);
	switch (module) {
		case 'email':
			moduleSection = currentSection?.querySelector(
				`#${moduleSectionIds['email']}`,
			);
			break;
		case 'twitter':
			moduleSection = currentSection?.querySelector(
				`#${moduleSectionIds['twitter']}`,
			);
			break;
		case 'phone':
			moduleSection = currentSection?.querySelector(
				`#${moduleSectionIds['phone']}`,
			);
			break;
		case 'instagram':
			moduleSection = currentSection?.querySelector(
				`#${moduleSectionIds['instagram']}`,
			);
			break;
		default:
			logger.debug('Display contact platform - no case match', module);
			break;
	}
	const reprensentativeSearchResults = moduleSection?.querySelectorAll(
		'.elected-search solid-display > div > solid-display',
	) as NodeListOf<SIBElement>;
	// clear all existing checkbox's
	const allCheckboxes = document.querySelectorAll(
		'input[type="checkbox"]',
	) as NodeListOf<HTMLInputElement>;
	for (const allCheckboxe of Array.from(allCheckboxes)) {
		allCheckboxe.checked = false;
	}
	for (const reprensentativeSearchResult of Array.from(
		reprensentativeSearchResults,
	)) {
		reprensentativeSearchResult.addEventListener('click', (e) => {
			if (module !== 'instagram') {
				e.preventDefault();
			}
			e.stopImmediatePropagation();
			// logger.debug("click",reprensentativeSearchResult);
			selectRepresentativeCardClick(reprensentativeSearchResult);
		});
	}
}

/** *********************
 *Select all displayed representatives
 ***********************/
export function selectAllRepresentatives(clickLocation: HTMLElement) {
	// get the line above the click
	logger.info('click', clickLocation);
	let representativeCards =
		clickLocation?.parentElement?.nextElementSibling?.querySelectorAll(
			':scope > div > solid-display,:scope solid-display > div > u4e-targeted-representative-widget > solid-display > div > solid-display',
		) as NodeListOf<HTMLElement>;
	logger.info('Select all reps', representativeCards);
	// if empty, like on Twitter card, check 2nd querySelector
	if (representativeCards?.length === 0) {
		representativeCards =
			clickLocation?.previousElementSibling?.querySelectorAll(
				':scope solid-display > div > solid-display',
			) as NodeListOf<HTMLElement>;
	}
	let checkedElement = false;
	for (const representativeCard of Array.from(representativeCards)) {
		// logger.debug("card is",representativeCard);
		// get innertext
		// if it's not "deactivate",
		checkedElement =
			representativeCard?.querySelector<HTMLInputElement>(
				'input[type=checkbox]',
			)?.checked ?? false;
		// logger.debug("checkbox",representativeCard.querySelector("input[type=checkbox]"));
	}
	// check all cards
	for (const representativeCard of Array.from(representativeCards)) {
		const chkbx = representativeCard?.querySelector<HTMLInputElement>(
			'input[type=checkbox]',
		);
		if (checkedElement) {
			// logger.debug("checkbox checked",checkedElement);
			// else uncheck all & place original content
			if (chkbx) chkbx.checked = false;
			representativeCard.classList.remove('active');
			clickLocation.innerText = 'Selectionner tou.te.s les élu.e.s';
		} else {
			// logger.debug("checkbox no checked",checkedElement);
			if (chkbx) chkbx.checked = true;
			representativeCard.classList.add('active');
			clickLocation.innerText = 'Annuler la séléction';
		}
	}
}

/** *********************
         START FUNCTION TO GET REPRESENTATIVES EMAILS
          //launches related functions
***********************/
export function getEmail(clickLocation: HTMLElement) {
	logger.debug('get email', clickLocation);
	const all_emails: string = '';
	const representativeDisplays: SIBElement[] = [];
	// select parent section
	const representativeEmailSection = clickLocation.closest('.email-module');
	// logger.debug("closest email section",representativeEmailSection);
	// select the representative sections
	const representativeEmailSubSections =
		representativeEmailSection?.querySelectorAll(
			':scope > section',
		) as NodeListOf<HTMLElement>;
	// for each section, get the visibility status & Keep only the displayed one
	// logger.debug("sections are",representativeEmailSubSections);
	for (const representativeMainDisplay of Array.from(
		representativeEmailSubSections,
	)) {
		const displayStatus = representativeMainDisplay.style.display;
		// logger.debug("section is",representativeMainDisplay,"dispay is",displayStatus);
		if (displayStatus !== 'none') {
			const allRepresentativeDisplays =
				representativeMainDisplay.querySelectorAll(
					':scope  solid-display > div > solid-display',
				) as NodeListOf<SIBElement>;
			// logger.debug("representatitves are", allRepresentativeDisplays);
			for (const allRepresentativeDisplay of Array.from(
				allRepresentativeDisplays,
			)) {
				representativeDisplays.push(allRepresentativeDisplay);
			}
		}
	}

	const selectedRepresentatives: SIBElement[] = [];
	// logger.debug("all displays are",representativeDisplays);
	for (const representativeDisplay of representativeDisplays) {
		// count the number of checked items
		const box = representativeDisplay?.querySelector<HTMLInputElement>(
			'input[type=checkbox]',
		);
		if (box?.checked) {
			selectedRepresentatives.push(representativeDisplay);
		}
	}
	setRepresentativeEmails(all_emails, selectedRepresentatives);
}

/** *********************
        GET EMAILS OF SELECTED REPRESENTATIVES 
        && PUSH THEM TO FORM FIELD 
***********************/
function setRepresentativeEmails(
	all_emails: string,
	selectedRepresentatives: SIBElement[],
) {
	// logger.debug("the representative display used",selectedRepresentatives,all_emails);
	for (const selectedRepresentative of Array.from(selectedRepresentatives)) {
		const representativeEmails = selectedRepresentative.querySelectorAll(
			'solid-multiple[name=directEmails] solid-display-value,solid-multiple-div[name=directEmails] solid-display-value',
		) as NodeListOf<HTMLElement>;
		// logger.debug("emails are",representativeEmails);
		for (const el of Array.from(representativeEmails)) {
			// get text content
			const representativeEmail = el.innerText
				.replace(/\n/g, '')
				.replace(/\s/g, '');
			if (!all_emails.includes(representativeEmail)) {
				// push each value to all emails
				all_emails += `${representativeEmail}, `;
			}
		}
	}
	// clean up the final string
	// logger.debug("all emails",all_emails);
	if (all_emails.length !== 0) {
		all_emails = all_emails.replace('undefined', '');
		all_emails = all_emails.replace(/(, $)/, '');
	}
	/*
  	This needs to change - only have 1 mail in use so we don't need an actual
	query select all and loop
	Just need to adapt template first
  */
	// push content to form(s)
	const displayEmailBlocks = document.querySelectorAll(
		'.send-email-elected-email input',
	) as NodeListOf<HTMLInputElement>;
	for (const displayEmailBlock of Array.from(displayEmailBlocks)) {
		displayEmailBlock.value = all_emails;
	}
}

/** *********************
        ADD/REMOVE REQUIRED ATTRIBUTE OF ELEMENT(S) 
***********************/

function attributeRequired(
	locations: HTMLElement | NodeListOf<HTMLElement> | HTMLElement[],
	itemSelector: Parameters<HTMLElement['querySelector']>[0],
	action: 'add' | 'remove',
) {
	// select each checkbox div
	// let checkboxDivs = representativeDisplay.querySelectorAll("div[name=select]");
	const elementNodeCheck = locations instanceof NodeList;
	const elemementArrayCheck = Array.isArray(locations);
	// logger.debug("attribute location",locations,elementNodeCheck,elemementArrayCheck);

	const toggleRequired = (el: HTMLElement | null) => {
		if (!el) return;
		if (action === 'add') {
			el.setAttribute('required', 'true');
		} else if (action === 'remove') {
			el.setAttribute('required', 'false');
		}
	};

	if (elementNodeCheck || elemementArrayCheck) {
		for (const location of Array.from(locations)) {
			toggleRequired(location?.querySelector(itemSelector));
		}
	} else {
		toggleRequired(locations?.querySelector(itemSelector));
	}
}

/** *********************
 * Display Modals
 * @param {string} Id id of modal wanted
 **********************/

export function displayModal(id: string) {
	logger.debug('displaying modal', id);
	const modal = document.getElementById(id);
	if (!modal) return;
	modal.style.display = 'flex';

	const close = modal.querySelector<HTMLElement>('.close');
	if (close)
		close.onclick = () => {
			modal.style.display = 'none';
		};
	// When the user clicks anywhere outside of the modal, close it
	window.onclick = (e) => {
		if (e.target === modal) {
			modal.style.display = 'none';
		}
	};
}

/** *********************
        HIDE EMPTY SECTION ON ACTION PAGE 
***********************/
export async function visibilityControler(
	solidDisplay: SIBElement,
	parentSection: HTMLElement,
) {
	setTimeout(() => {
		
		// logger.debug("visibility controler",solidDisplay,parentSection);
		const section = parentSection;
		// handle steps seperately
		// get content of the display
	
		// Check if display is image
		const isImageBlock = solidDisplay.querySelector('img');
		logger.debug('is image display?', isImageBlock);
		let content = '';
		if (isImageBlock != null) {
			content = isImageBlock.src;
		} else {
			content = solidDisplay.querySelector('div')?.innerText ?? '';
		}
		logger.debug('visibility div content', solidDisplay, content);
		// remove spaces in content for check
		content = content.replace(/\s/g, '');
		content = content.replace(/\n/g, '');
		// logger.debug("visibility div compare content",content);
		if (content === '') {
			section.style.display = 'none';
		} else {
			section.style.display = 'block';
		}
	}, 0);
}

// Updated way to display targetted sections
export async function visibilityControlerTargettedMethod(section: HTMLElement) {
	// if module #representative-contact-modules is displayed, on navigate it's not hidden
	// same for #manual-contact-content-email
	// so start by hidding it in case

	const contactModules = document.querySelectorAll<HTMLElement>(
		'#representative-contact-modules,#manual-contact-content-email,#email-form-section',
	);
	if (!contactModules) return;
	logger.debug('contact modules are', contactModules);
	for (const contactModule of Array.from(contactModules)) {
		contactModule.style.display = 'none';
	}

	const chosenMethodBlock = (await asyncQuerySelector(
		'*[name=typeOfTargetting]',
		section,
	)) as HTMLElement;
	setTimeout(() => {
		logger.debug('chosen method block', chosenMethodBlock);
		const chosenMethod = chosenMethodBlock.innerText;
		logger.debug('Chosen method', chosenMethod, chosenMethodBlock.outerHTML);

		const postalCodeTargetingBlock = document.querySelector<HTMLElement>(
			'section#global-representative-targeting',
		);
		const specificTargetsBlock = document.querySelector<HTMLElement>(
			'section#manual-representative-targeting',
		);
		logger.debug(
			'targetting sections',
			postalCodeTargetingBlock,
			specificTargetsBlock,
		);
		if (!postalCodeTargetingBlock || !specificTargetsBlock)
			throw new Error(
				"could not find elements 'section#global-representative-targeting' or 'section#manual-representative-targeting'",
			);
		if (chosenMethod === 'postalCode') {
			logger.debug(
				'chosenMethode is',
				chosenMethod,
				' hide ',
				specificTargetsBlock,
			);
			// display postal code block & hide others
			postalCodeTargetingBlock.style.display = 'block';
			specificTargetsBlock.style.display = 'none';
		} else if (chosenMethod === 'specificRepresentatives') {
			logger.debug(
				'chosenMethode is',
				chosenMethod,
				' hide ',
				postalCodeTargetingBlock,
			);
			// display representative block& hide others
			postalCodeTargetingBlock.style.display = 'none';
			specificTargetsBlock.style.display = 'block';
		}
		// preperation for "geography"
		else if (chosenMethod === 'byGeography') {
			logger.debug('byGeography');
			postalCodeTargetingBlock.style.display = 'none';
			specificTargetsBlock.style.display = 'none';
		} else if (chosenMethod === 'none' || chosenMethod === '') {
			logger.debug('none, hide all');
			// hide all blocks
			postalCodeTargetingBlock.style.display = 'none';
			specificTargetsBlock.style.display = 'none';
		} else {
			/* Kept for old action, cf comment near function */
			logger.debug('Type of targetting not set - Testing depreciated method');
			visibilityControlerRepresentative(section);
		}
	}, 0);
}

// Handle representative sections
/*
  DEPRECIATED AND REPLACED BY visibilityControlerTargettedMethod
  KEPT FOR OLD ACTIONS THAT HAVE NOT INFORMED THE CHOSEN METHOD VALUE
 
  KNOWN BUG -> When loading slowly, nothing is displayed 
 */
function visibilityControlerRepresentative(section: HTMLElement) {
	setTimeout(() => {
		logger.warn(
			'Depreciation warning for: visibilityControlerRepresentative()',
		);
		const representativeCTA = section.querySelector<HTMLElement>(
			'u4e-multiple-js-button-representative-contact[name="targetedOfficials"] [name="name"]',
		);
		// logger.debug("representative visibility controler",section,representativeCTA);
		if (representativeCTA == null) {
			section.style.display = 'none';
		} else {
			section.style.display = 'block';
		}
	}, 1500);
}


// handle action step section
// REPLACING WITH CSS .css-visibility-controler
// async function visibilityControlerActionStep(section: HTMLElement) {
// 	// let stepContent = section.querySelector<HTMLElement>(
// 	// 	'u4e-multiple-action-steps > solid-display > div',
// 	// )?.innerText;
// 	const stepRequest = (await asyncQuerySelector(
// 		'u4e-multiple-action-steps > solid-display > div',
// 		section,
// 	)) as HTMLElement;
// 	setTimeout(() => {
// 		logger.debug('async step visibility, content is ', stepRequest.innerText);
// 		let stepContent = stepRequest.innerText;
// 		// if (stepContent){
// 		// }
// 		// logger.debug("step content is",stepContent);
// 		if (stepContent) {
// 			stepContent = stepContent.replace(/\s/g, '');
// 			stepContent = stepContent.replace(/\n/g, '');
// 			logger.debug('async step content is', stepContent);
// 			if (stepContent === '') {
// 				section.style.display = 'none';
// 			} else {
// 				section.style.display = 'block';
// 			}
// 		} else {
// 			section.style.display = 'none';
// 		}
// 	}, 2500);
// 	// return section
// }

// REPLACING WITH CSS .css-visibility-controler
// /** *********************
//         Action Steps
//           * If active is True, add active class to element
//           * Else hide section
// ***********************/
// function styleActiveActionStep(solidDisplay: SIBElement) {
// 	setTimeout(() => {
// 		// get all "active" fields in display
// 		const activeFields = solidDisplay.querySelectorAll(
// 			'u4e-display-active-step',
// 		);
// 		// logger.debug("step fields",activeFields,solidDisplay);
// 		// if none, hide section
// 		for (const activeField of Array.from(activeFields)) {
// 			const activeFieldValue = activeField.getAttribute('value') ?? '';
// 			// if Active is true, set "active" class to parent display
// 			if (activeFieldValue.toLowerCase() === 'true') {
// 				activeField.closest('solid-display')?.classList.add('active');
// 				// logger.debug(activeField.closest('solid-display').classList);
// 			}
// 		}
// 	}, 2500);
// }
/** *********************
    LANGUAGE SWITCH         
***********************/
export function manageSelectLanguage() {
	const languageSelects = document.querySelectorAll(
		'.languageChoice',
	) as NodeListOf<HTMLElement>;
	logger.debug('language switches are', languageSelects);
	for (const item of Array.from(languageSelects)) {
		// logger.debug("language item",item);
		if (item.tagName === 'SOLID-FORM') {
			// We listen the selected option for the language
			item.addEventListener('change', () => {
				languageRedirect(item as SIBElement, 'form');
			});
		} else if (item.tagName === 'SOLID-DISPLAY') {
			const flags = item.querySelectorAll(
				'solid-display',
			) as NodeListOf<SIBElement>;
			for (const flag of Array.from(flags)) {
				flag.addEventListener('click', () => {
					languageRedirect(flag, 'display');
				});
			}
		}
	}
}
async function languageRedirect(item: SIBElement, tagType: 'form' | 'display') {
	// We retrieve element of the url
	let pathAfterThePrefix = window.location.pathname;
	let countryCode = "" // set none for all cases where language is not in URL
	let countryCodeSearch = window.location.pathname.split('/')[1]
	logger.debug("country code search",countryCodeSearch)
	if (countryCodeSearch.length === 2){
		countryCode = `${window.location.pathname.split('/')[1]}/`
		//remove country code from url
		pathAfterThePrefix = pathAfterThePrefix.replace(countryCode,"")
		logger.debug("path without country code",pathAfterThePrefix)
	}
	logger.debug('path after prefix',pathAfterThePrefix,'original path',window.location.pathname)
	// if (countryCode == "") {
	// 	logger.debug("No path after language",pathAfterThePrefix);
	// 	pathAfterThePrefix = '';
	// }
	// logger.debug("path",pathAfterThePrefix,window.location.pathname);
	const baseUrl = `${location.protocol}//${location.host}`;
	// if it's a form
	if (tagType === 'form') {
		// If the selected language is french
		const languagesList = await item.component.resource['ldp:contains'];
		// logger.debug("language list",languagesList)
		// for (const element of object) {

		// }
		languagesList.forEach(
			async (resource: { '@id': string; country: string ;url_code : string;}) => {
				// logger.debug("start language foreach",resource)
				if (item.component.value.country['@id'] == resource['@id']) {
					let redirect;
					if ((await resource.country) == 'Global') {
						redirect = `${baseUrl}/${pathAfterThePrefix}`;
					} else {
						// sibStore.selectLanguage((await resource.code).toLowerCase());
						logger.debug("resource country",(await resource),(await resource.country)," label :",(await resource.url_code));
						redirect = `${baseUrl}/${(
							await resource.url_code
						).toLowerCase()}${pathAfterThePrefix}`;
					}
					logger.debug("destination url",redirect)
					logger.debug("window language before store",await window.sibStore._getLanguage(),resource.url_code,'navigator : ',navigator.language)
					await window.sibStore.selectLanguage(await resource.url_code)
					
					// window.sibStore.selectLanguage = await resource.url_code;

					logger.debug("window language after '",await window.sibStore._getLanguage(),"' navigator : '",navigator.language)
					document.location.href = redirect;
					
				}
			},
		);
	} else if (tagType === 'display') {
		const language = item
			?.querySelector('[name=country]')
			?.textContent?.toLowerCase();
		let redirect;
		if (language === 'global') {
			redirect = '#';
			window.scrollTo({ top: 0, behavior: 'smooth' });
		} else {
			redirect = `${baseUrl}/${language}/${pathAfterThePrefix}`;
		}
		document.location.href = redirect;
	}
}


/** *********************
        MAP SEARCH & INPUT
***********************/

export async function solidMapSearch(clickLocation: HTMLElement) {
	const inputForm = clickLocation.parentElement?.closest('solid-form');
	const inputStreet =
		inputForm?.querySelector<HTMLInputElement>('input[name="road"]')?.value ??
		'';
	const inputCity =
		inputForm?.querySelector<HTMLInputElement>('input[name="city"]')?.value ??
		'';
	const searchLoader = inputForm?.querySelector<HTMLElement>(
		'u4e-forms-display[name="searchLoader"]',
	);

	// const inputStreetEncoded = encodeURI(inputStreet);
	// const inputCityEncoded = encodeURI(inputCity);
	// const requestUrl = `https://nominatim.openstreetmap.org/search?q=${inputStreetEncoded},+${inputCityEncoded}+&format=json&polygon=1&addressdetails=1`;
	// logger.debug("location is",inputStreet,inputCity,requestUrl);

	if (searchLoader) searchLoader.style.display = 'block';
	const response = await fetch(
		`https://nominatim.openstreetmap.org/search?q=${inputStreet},+${inputCity}+&format=json&polygon=1&addressdetails=1`,
	);
	const data = await response.json();
	if (searchLoader) searchLoader.style.display = 'none';
	// logger.debug("response",data);

	try {
		// get values from response
		const locationName = data[0].display_name;
		// display hide error block, display validation && add lat/lng
		const locationError = inputForm?.querySelector<HTMLElement>(
			"u4e-forms-display-label[name='locationError']",
		);
		if (locationError) locationError.style.display = 'none';
		const locationValidator = inputForm?.querySelector<HTMLElement>(
			"u4e-forms-display-label[name='locationValidator']",
		);
		if (locationValidator) {
			locationValidator.style.display = 'block';
			locationValidator.textContent = locationName;
		}
		const locationValidator2 = inputForm?.querySelector<HTMLElement>(
			"u4e-forms-display-label[name='locationValidator2']",
		);
		if (locationValidator2) locationValidator2.style.display = 'block';

		const locationLat = data[0].lat;
		const locationLon = data[0].lon;
		let city = data[0].address['city'];
		if (city === undefined) {
			city = data[0].address['town'];
			// logger.debug("in if",city);
		}
		if (city === undefined) {
			city = inputCity;
		}
		// logger.debug("original lats",data[0],city,locationLat,locationLon);
		// fill form values with responses
		const latInput =
			inputForm?.querySelector<HTMLInputElement>('input[name=lat]');
		if (latInput) latInput.value = Number.parseFloat(locationLat).toFixed(12);
		const lngInput =
			inputForm?.querySelector<HTMLInputElement>('input[name=lng]');
		if (lngInput) lngInput.value = Number.parseFloat(locationLon).toFixed(12);
		const cityInput =
			inputForm?.querySelector<HTMLInputElement>('input[name=city]');
		if (cityInput) cityInput.value = city;
	} catch (e) {
		// if error, display error block
		let locationName = `${inputStreet} ${inputCity}`;
		if (locationName === ' ') {
			locationName = 'None';
		}
		const locationError = inputForm?.querySelector<HTMLElement>(
			"div[name='locationError'] p",
		);
		if (locationError) {
			locationError.textContent = locationName;
			locationError.style.display = 'block';
		}
		const locationValidator = inputForm?.querySelector<HTMLElement>(
			"u4e-forms-display-label[name='locationValidator']",
		);
		if (locationValidator) locationValidator.style.display = 'none';
		const locationValidator2 = inputForm?.querySelector<HTMLElement>(
			"u4e-forms-display-label[name='locationValidator2']",
		);
		if (locationValidator2) locationValidator2.style.display = 'none';
	}
}
export function formatMapResult(feature: {
	properties: {
		name?: string;
		housenumber?: string;
		street?: string;
		city?: string;
		postcode?: string;
		country?: string;
	};
}) {
	// logger.debug("format map script");
	const address = feature.properties;
	let name = '';

	name += address.name ? `${address.name} ` : '';
	name += address.housenumber ? `${address.housenumber} ` : '';
	name += address.street ? `${address.street}, ` : '';
	name += address.city ? `${address.city} ` : '';
	name += address.postcode ? `${address.postcode}, ` : '';
	name += address.country ? address.country : '';

	return name;
}

/** **********
 * Return the value of a cooke
 * @param {name} Name of the cookie you want
 *
 *
 * NOT : POSSIBLY USELESS - CRSF token was invalid so @exempt added to view (only updates the counter)
 ************/
export function getCookie(name: string) {
	let cookieValue = null;
	if (document.cookie && document.cookie !== '') {
		const cookies = document.cookie.split(';');
		for (let i = 0; i < cookies.length; i++) {
			const cookie = cookies[i].trim();
			// Does this cookie string begin with the name we want?
			if (cookie.substring(0, name.length + 1) === `${name}=`) {
				cookieValue = decodeURIComponent(cookie.substring(name.length + 1));
				break;
			}
		}
	}
	return cookieValue;
}

/** **********
 * Event to count the number of tweets in an action
 * https://git.risefor.org/applications/risefor-front/-/issues/113
 ************/
export function addToPlateformCounter(url: string, context = 'twitter') {
	// Federation Improvement needed - https://git.risefor.org/applications/risefor-front/-/issues/144
	logger.debug(`Add counter for ${context} on page ${url}`);
	let target_url = '/twitter-click-counter/';
	if (context === 'instagram') {
		target_url = '/instagram-click-counter/';
	}
	logger.debug(`target url is ${target_url} url data is ${url}`);
	fetch(target_url, {
		method: 'POST',
		headers: filterNullKeys({
			'X-CSRFToken': getCookie('csrftoken'),
		}),
		body: JSON.stringify({
			currentUrl: url, // Pass the current URL in the body
		}),
	})
		.then((response) => {
			if (response.ok) {
				logger.debug(`${context} click counter incremented successfully`);
			} else {
				console.error(`Failed to increment ${context} click counter`);
			}
		})
		.catch((error) => {
			console.error('An error occurred:', error);
		});
}

/** **********
 * Action Page - Email Module
 * On click 'validate' after search, check if representatives
 * Where chosen
 * @param {name} Name of the cookie you want
 ************/
export function checkChosenRepresentatives(clickLocation: HTMLElement) {
	const representativeDisplays: SIBElement[] = [];
	// select parent section
	const representativeEmailSection = clickLocation.closest('.email-module');
	// logger.debug("closest email section",representativeEmailSection);
	// select the representative sections
	const representativeEmailSubSections =
		representativeEmailSection?.querySelectorAll<HTMLElement>(
			':scope > section',
		);
	// for each section, get the visibility status & Keep only the displayed one
	// logger.debug("sections are",representativeEmailSubSections);
	if (!representativeEmailSubSections) {
		return;
	}
	for (const representativeMainDisplay of Array.from(
		representativeEmailSubSections,
	)) {
		const displayStatus = representativeMainDisplay.style.display;
		// logger.debug("section is",representativeMainDisplay,"dispay is",displayStatus);
		if (displayStatus !== 'none') {
			const allRepresentativeDisplays =
				representativeMainDisplay.querySelectorAll<SIBElement>(
					':scope  solid-display > div > solid-display',
				);
			// logger.debug("representatitves are", allRepresentativeDisplays);
			for (const allRepresentativeDisplay of Array.from(
				allRepresentativeDisplays,
			)) {
				representativeDisplays.push(allRepresentativeDisplay);
			}
		}
	}

	/*
	 * Check if there are checked items
	 */
	let checkedItems = 0;
	// const selectedRepresentatives = [];
	// logger.debug("all displays are",representativeDisplays);
	for (const representativeDisplay of Array.from(representativeDisplays)) {
		// count the number of checked items
		const box = representativeDisplay?.querySelector<HTMLInputElement>(
			'input[type=checkbox]',
		)?.checked;
		if (box === true) {
			checkedItems = checkedItems + 1;
		}
	}
	// if there's no checked email display error
	if (checkedItems === 0) {
		logger.debug('checked 0 are', checkedItems);
		showHideBlocks(
			'#validateRepresentativeChoices',
			'#email-form-section',
			'none',
		);
		displayModal('modal-noemails');
	}
	// pass functions previously in html button
	getEmail(clickLocation);
	showHideBlocks(
		'#validateRepresentativeChoices',
		'#email-form-section',
		'block',
		true,
	);
}
