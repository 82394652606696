// import * as winston from 'winston';

// const logger = winston.createLogger({
//   level: 'info',
//   format: winston.format.combine(
//     winston.format.timestamp(),
//     winston.format.printf(({ timestamp, level, message }) => {
//       return `${timestamp} [${level}]: ${message}`;
//     })
//   ),
//   transports: [
//     new winston.transports.Console(),
//     new winston.transports.File({ filename: 'typescript.log' })
//   ]
// });

// export default logger;
// const path = require('path');
const pino = require('pino');

const logger = pino({
  level: 'trace', // Log level
  transport: {
    target: 'pino-pretty',
    options: { // All pretty-print options go here
      colorize: true,
      translateTime: 'yyyy-mm-dd HH:MM:ss',
      messageFormat: '{time} {msg} {pid}',
      // ignore: 'pid,hostname', // Uncomment if needed
      destination: 2, // 2 = stderr (usually shown in Vite)
      sync: true, // Ensure logs appear immediately
    },
  },
  // Optional: Uncomment to log to a file
  // stream: pino.destination('./typescript.log') 
});

/*
trace: For tracing information (very detailed).
debug: For debugging information.
info: For general information messages.
warn: For warning messages.
error: For error messages.
fatal: For critical error messages that result in termination.*/
export default logger;