import DOMPurify from 'dompurify';
// @ts-nocheck
import {
	LitElement,
	type TemplateResult,
	type PropertyValues,
	html,
	css,
	unsafeCSS,
} from 'lit';
import { unsafeHTML } from 'lit-html/directives/unsafe-html.js';
import { customElement, property, state } from 'lit/decorators.js';
import logger from '@scripts/logger';

import {
	TwitterEOMultipleContentOptions,
	consultationChooseOption,
	displayElement,
	scrollToDestination,
} from '@scripts/risefor';
import ButtonStyle from '@styles/src/_vars/_grid.scss?inline';
import ButtonStyle from '@styles/src/shared/_buttons.scss?inline';
import TabStyle from '@styles/src/components/_display-tabs.scss?inline';

export type TabDisplayProps = {
	// name:string,
	// value:,
	class?: string;
	fields: string;
	dataSrc: string;
	ctaType: string;
	ctaLabel: string;
	ctaClass: string;
	ctaJs?: boolean;
	ctaCopyTarget?: string;
	ctaScrolltoTarget?: string;
	ctaLaunchScript?: string;
};

// Declase format of tab
interface Tab {
	title: string;
	content: string;
}
// Ldp nested fields ressources
interface LdpContainsItem {
	'@id': string;
}
@customElement('risefor-display-tabs')
export class RiseforDisplayTabs extends LitElement {
	static styles = css`
		${unsafeCSS(TabStyle)};
		${unsafeCSS(ButtonStyle)};
	`;

	@property({ attribute: 'class', type: String, reflect: true })
	class: TabDisplayProps['class'] = '';

	@property({ attribute: 'fields', type: String, reflect: true })
	fields: TabDisplayProps['fields'] = '';

	@property({ attribute: 'data-src', type: String, reflect: true })
	dataSrc: TabDisplayProps['dataSrc'] = '';

	@property({ attribute: 'cta-type', type: String, reflect: true })
	ctaType: TabDisplayProps['ctaType'] = '';

	@property({ attribute: 'cta-label', type: String, reflect: true })
	ctaLabel: TabDisplayProps['ctaLabel'] = '';

	@property({ attribute: 'cta-class', type: String, reflect: true })
	ctaClass: TabDisplayProps['ctaClass'] = '';

	@property({ attribute: 'cta-js', type: Boolean, reflect: true })
	ctaJs: TabDisplayProps['ctaJs'];

	@property({ attribute: 'cta-copy-target', type: String, reflect: true })
	ctaCopyTarget: TabDisplayProps['ctaCopyTarget'];

	@property({ attribute: 'cta-scrollto-target', type: String, reflect: true })
	ctaScrolltoTarget: TabDisplayProps['ctaScrolltoTarget'];

	@property({ attribute: 'cta-launch-script', type: String })
	ctaLaunchScript: TabDisplayProps['ctaLaunchScript'];

	@state() tabs: Tab[] = []; // Array of objects to represent the tabs
	@state() activeIndex = 0; // Index of the active tab
	@state() loading = true; // Loading state
	@state() error = false; // Error state

	private _observer: IntersectionObserver | null = null;

	connectedCallback() {
		super.connectedCallback();
		this._setupIntersectionObserver();
	}

	disconnectedCallback() {
		super.disconnectedCallback();
		this._disconnectObserver(); // Clean up the observer when the component is disconnected
	}

	willUpdate(changedProperties: PropertyValues) {
		if (changedProperties.has('dataSrc')) {
			// Reconnect the observer when dataSrc changes
			this._disconnectObserver();
			this._setupIntersectionObserver();
		}
	}

	private _setupIntersectionObserver() {
		this._observer = new IntersectionObserver(
			(entries) => {
				for (const entry of entries) {
					if (entry.isIntersecting) {
						// Start fetching data when the component is in the viewport
						if (this.dataSrc) {
							this._fetchTabsData();
						}
						this._disconnectObserver(); // Stop observing after the first load
					}
				}
			},
			{
				threshold: 0.1, // Trigger when 10% of the element is visible
			},
		);

		this._observer.observe(this);
	}

	private _disconnectObserver() {
		if (this._observer) {
			this._observer.disconnect();
			this._observer = null;
		}
	}

	_CheckSibCache(url: string) {
		const cacheExists = window.sibStore?.cache.get(url);
		if (cacheExists) {
			return cacheExists;
		}
	}

	// Fetch and process tabs data
	async _fetchTabsData() {
		if (!this.dataSrc) {
			logger.debug('Tabs - No dataSrc provided, skipping fetch.');
			return;
		}

		// Get the language from a global variable set by Django
		const language = document.documentElement.lang || 'fr';

		this.loading = true;
		this.error = false;

		try {
			let data;
			let wantedUrlIds = [];
			// Test getting data from sibCache, if not fetch from server
			try {
				const sibCacheMainResource = this._CheckSibCache(this.dataSrc.trim());
				// const sibCacheMainResource = false ; // for dev
				console.dir(sibCacheMainResource);

				const sibRessource = await sibCacheMainResource.getResourceData();
				console.dir(sibRessource);
				data = sibRessource;

				// get the URLs of ressources with wanted data
				const KEY_BASE = 'https://cdn.startinblox.com/owl#';
				const ressourceNestedUrl = data[KEY_BASE + this.fields];
				if (ressourceNestedUrl) {
					const resourceUrlCache = this._CheckSibCache(
						ressourceNestedUrl['@id'],
					);
					const resourceUrlCacheData = resourceUrlCache.getResourceData();
					logger.debug(
						`Tabs - sib cache ressouce data is ${resourceUrlCacheData}`,
					);
					console.dir(resourceUrlCacheData);
					if (resourceUrlCacheData) {
						const resouceList =
							resourceUrlCacheData['http://www.w3.org/ns/ldp#contains'];
						for (const url of resouceList) {
							wantedUrlIds.push(url['@id']);
						}
					}
				}
			} catch (error) {
				logger.warn(`Tabs - failed fetching from cache ${error}`);
				const response = await fetch(this.dataSrc);
				data = await response.json();
				// Extract the array of values in the wanted field
				wantedUrlIds = data[this.fields]['ldp:contains'].map(
					(item: LdpContainsItem) => item['@id'],
				);
			}
			logger.debug(`Tabs start - After fectch action ${this.dataSrc}, ${data}`);
			logger.debug(
				`tabs - launch custome scripts ${JSON.stringify(this.ctaLaunchScript)} ${typeof this.ctaLaunchScript}`,
			);

			// Fetch all data concurrently
			const fetchPromises = wantedUrlIds.map(async (urlId: string) => {
				// Check if URI is in cache
				// const sibCache = window.sibStore?.cache.get(urlId);
				// logger.debug(`TABS - url id in sib cache ${sibCache}`);

				// TODO : if cache, get from there
				try {
					const wantedDataResponse = await fetch(urlId, {
						headers: {
							'Accept-Language': language, // Pass the language
						},
					}).then((res) => res.json());

					const tabLabel = wantedDataResponse.name; // Replace 'name' dynamic field later
					let tabContent = wantedDataResponse.content; // Replace 'content' dynamic field later

					// Replace newlines with <br> for HTML rendering
					tabContent = tabContent.replace(/\r?\n/g, '<br>');

					// Sanitize the tab content
					const sanitizedTabContent = DOMPurify.sanitize(tabContent);

					return { title: tabLabel, content: sanitizedTabContent };
				} catch (error) {
					logger.error(`Tabs - Error fetching data for ${urlId}`, error);
					return null;
				}
			});

			// Wait for all fetches to complete
			const finalTabs = (await Promise.all(fetchPromises)).filter(
				(tab) => tab !== null,
			);

			this.tabs = finalTabs;
			this.loading = false;
		} catch (error) {
			this.loading = false;
			this.error = true;
			logger.error('Tabs - Error in tabs component', error);
		}
	}

	// Set the active tab
	_setActiveTab(index: number) {
		this.activeIndex = index;
	}

	_getTabContent() {
		const tabbed_content = html` <ul class="tabs-nav">
				${this.tabs.map(
					(tab, index) => html`
						<li
							class="${this.activeIndex === index
								? 'list-tab-active active tab-module-li'
								: 'tab-module-li'}"
							@click=${() => this._setActiveTab(index)}
						>
							${tab.title}
						</li>
					`,
				)}
			</ul>
			<div class="tabs-stage" style="white-space: normal;">
				<p class="tab-content">
					${unsafeHTML(
						this.tabs[this.activeIndex]?.content || 'No content available',
					)}
				</p>
			</div>`;

		return tabbed_content;
	}
	_handleTwitterClick(e: Event) {
		e.preventDefault();
		TwitterEOMultipleContentOptions(e.currentTarget);
	}

	_scrollToTarget() {
		const scrollInfo: Array = this.ctaScrolltoTarget.split(',');
		const destinationIdOrNodeSearch = scrollInfo[0].replaceAll("'", '').trim();
		const blockDestination = scrollInfo[1].replaceAll("'", '').trim();
		logger.debug(
			`destiantion is ${destinationIdOrNodeSearch} position ${blockDestination}`,
		);

		const destinationIdOrNode = document.querySelector(
			destinationIdOrNodeSearch,
		);
		logger.debug(`scroll destination is ${destinationIdOrNode}`);
		if (destinationIdOrNode) {
			(destinationIdOrNode as HTMLElement).scrollIntoView({
				behavior: 'smooth',
				block: blockDestination,
				// offset: addOffset, property does not seem to exist
			});
		}
	}

	_handleCustomJsClick(e) {
		e.preventDefault();
		const tabContent = this.shadowRoot?.querySelector('*[class=tab-content]');
		if (tabContent) {
			const textToCopy = tabContent?.textContent.replace(/\t/g, '');

			logger.debug(`tab content is ${textToCopy}`);
			navigator.clipboard
				.writeText(textToCopy)
				.then(() => {
					window.alert('Le texte a bien été copié'); //change to modal
				})
				.catch((err) => {
					window.alert('Une erreur est survenue:', err); //change to modal
				});

			logger.debug(`scroll to target ? ${this.ctaScrolltoTarget}`);
			if (this.ctaScrolltoTarget) {
				this._scrollToTarget();
			}
		}
	}

	// Launch custom script passed though attribute
	_launchCustomScripts() {
		if (!this.ctaLaunchScript) return;

		const scriptArray = this.ctaLaunchScript.includes(',')
			? this.ctaLaunchScript.split(',')
			: [this.ctaLaunchScript];

		const tabContent = this.shadowRoot?.querySelector('*[class=tab-content]');
		const textToCopy = tabContent?.innerText;

		for (const script of scriptArray) {
			// match function to launch
			if (script.includes('consultationChooseOption')) {
				consultationChooseOption(textToCopy);
				displayElement(this, '#consultation__step', 'block');
				scrollToDestination('targetID', 'consultation__step','start');
			} else {
				logger.error(`${script} is not valid`);
				logger.debug(new Error().stack);
			}
		}
	}

	_getCtaType() {
		let ctaHtml: TemplateResult;
		logger.debug(`cta type is ${this.ctaType}`);
		if (this.ctaType === 'cta') {
			ctaHtml = html`
				<div
					class="cta-3 main-color width-150px margin-auto mt-15px bold"
					@click="${this._handleTwitterClick}"
				>
					<p>${this.ctaLabel}</p>
				</div>
			`;
		} else if (this.ctaType === 'customJs') {
			ctaHtml = html`
				<div
					class="${this.ctaClass}"
					@click="${this._launchCustomScripts}"
				>
					<p>${this.ctaLabel}</p>
				</div>
			`;
		} else {
			if (this.ctaJs) {
				ctaHtml = html`
					<div class="${this.ctaClass}" @click="${this._handleCustomJsClick}">
						${this.ctaLabel}
					</div>
				`;
			} else {
				ctaHtml = html` <div class="${this.ctaClass}">${this.ctaLabel}</div> `;
			}
		}
		return ctaHtml;
	}

	render() {
		// this.error = true;
		if (this.loading) {
			return html`
				<div class="skeleton-loader">
					<div class="skeleton-tab"></div>
					<div class="skeleton-content"></div>
				</div>
			`;
		}

		if (this.error) {
			return html`<div class="notification is-warning is-light">
				<p>Une erreur est survenue</p>
			</div>`;
		}

		return html`
			<div class="tabs tab-module is-flex is-flex-direction-column">
				${this._getTabContent()} ${this._getCtaType()}
			</div>
		`;
	}
}

declare global {
	interface HTMLElementTagNameMap {
		'risefor-display-tabs': RiseforDisplayTabs;
	}
}
