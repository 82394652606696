import logger from "./logger";
// import { filterNullKeys } from "./utils";

/** **********
 * Law Tracker
 * On click, fill notification form for current law
 * @param {clickLocation} location of the click
 ************/
export function NotificationLoadLawID(clickLocation: HTMLElement) {
  logger.info("location of the clic", clickLocation);
  const notification_form = document.querySelector("#open-law-tracker_notif");
  const law_data_display = clickLocation.closest("solid-display");
  if (law_data_display) {
    const law_data_src = law_data_display.getAttribute("data-src");

    const notification_law_field =
      notification_form?.querySelector<HTMLInputElement>("input[name=law]");
    if (notification_law_field) {
      notification_law_field.value = JSON.stringify({ "@id": law_data_src });
    }
  }
}
